export function hasFeature(company, feature) {
    if (!company || !company.current_payment_plan) {
        return false;
    }

    const features = [
        ...(company.current_payment_plan.features || []),
        ...(company.features || [])
    ];

    return features.indexOf(feature) !== -1;
}

const featureMiddleware = async (store, to, from, next) => {
    const feature = to.meta && to.meta.feature;

    if (feature) {
        const company = store.state.company;

        if (!hasFeature(company, feature)) {
            return next('/');
        }

        return next();
    }

    return next();
};

export default featureMiddleware;
