import {getLocalStorageTokenKey, isLocalStorageEnabled} from "./localStorage";
import Axios from "axios";

const authMiddleware = async (store, to, from, next) => {
    const isGuest = to.meta && to.meta.guest;
    const isAuth = to.meta && to.meta.auth;
    const token = isLocalStorageEnabled() ? localStorage.getItem(getLocalStorageTokenKey()) : null;

    if (isGuest && !isAuth) {
        if ((store.state.user || token) && (!to.meta && to.meta.auth)) {
            return next('/');
        }

        return next();
    } else {
        if (store.state.user) {
            return next();
        }

        if (!token) {

            if(isGuest){
                return next()
            }

            return next('/login');
        }

        try {

            store.dispatch('setLoading', true);

            const response = await Axios.get('/api/user', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            store.dispatch('login', {
                user: response.data,
                token,
            })

            return next();
        } catch (exception) {
            store.dispatch('logout');

            return next('/login');
        }
    }
}

export default authMiddleware;
