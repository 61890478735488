<template>
    <div class="row" v-if="pagination.total && pagination.last_page > 1">
        <div class="col-12 text-center">
            <ul class="pagination justify-content-center">
                <li class="page-item" v-if="lastPage > 1" :class="!previousPage ? 'disabled' : null">
                    <a class="page-link" @click="gotoPage(previousPage)">Vorige</a>
                </li>

                <li class="page-item" v-for="(item, index) in beforeList" :class="{ 'active': item === pagination.current_page }" :key="'pagination1_' + index">
                    <a class="page-link" @click="gotoPage(item)">{{ item }}</a>
                </li>
                <li class="page-item" v-if="middleList.length">
                    <span>...</span>
                </li>
                <li class="page-item" v-for="(item, index) in middleList" :class="{ 'active': item === pagination.current_page }" :key="'pagination2_' + index">
                    <a class="page-link" @click="gotoPage(item)">{{ item }}</a>
                </li>
                <li class="page-item" v-if="afterList.length">
                    <span>...</span>
                </li>
                <li class="page-item" v-for="(item, index) in afterList" :class="{ 'active': item === pagination.current_page }" :key="'pagination3_' + index">
                    <a class="page-link" @click="gotoPage(item)">{{ item }}</a>
                </li>

                <li class="page-item" :class="!nextPage ? 'disabled' : null">
                    <a class="page-link" @click="gotoPage(nextPage)">Volgende</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {

        props: {
            'pagination': {
                'required': true
            }
        },

        data: function () {
            return {};
        },

        computed: {
            previousPage: function () {
                if (this.pagination.current_page > 1) {
                    return this.pagination.current_page - 1;
                }

                return 0;
            },
            nextPage: function () {
                if (this.pagination.current_page + 1 <= this.lastPage) {
                    return this.pagination.current_page + 1;
                }

                return 0;
            },
            lastPage: function () {
                return Math.ceil(this.pagination.total / this.pagination.per_page);
            },
            beforeList: function () {
                let _from = 0;
                let _to = 0;
                if (this.lastPage <= 10) {
                    _from = 1;
                    _to = this.lastPage;
                } else {
                    if (this.pagination.current_page < 7) {
                        _from = 1;
                        _to = 8;
                    } else {
                        _from = 1;
                        _to = 2;
                    }
                }

                let _arr = [];
                if (_from !== 0 && _to !== 0) {
                    while (_from <= _to) {
                        _arr.push(_from);
                        _from++;
                    }
                }

                return _arr;
            },
            middleList: function () {
                let _from = 0;
                let _to = 0;

                if (this.lastPage > 10 && this.pagination.current_page >= 7) {
                    _from = this.pagination.current_page - 3;
                    _to = this.pagination.current_page + 3;

                    if (_to > this.lastPage - 4) {
                        _from = 0;
                        _to = 0;
                    }
                }

                let _arr = [];

                if (_from !== 0 && _to !== 0) {
                    while (_from <= _to) {
                        _arr.push(_from);
                        _from++;
                    }
                }

                return _arr;
            },
            afterList: function () {
                let _from = 0;
                let _to = 0;

                if (this.lastPage > 10) {

                    if (this.pagination.current_page < this.lastPage - 6) {
                        _from = this.lastPage - 1;
                        _to = this.lastPage;
                    } else {
                        _from = this.lastPage - 7;
                        _to = this.lastPage;
                    }
                }

                let _arr = [];

                if (_from !== 0 && _to !== 0) {
                    while (_from <= _to) {
                        _arr.push(_from);
                        _from++;
                    }
                }

                return _arr;
            }
        },

        methods: {
            gotoPage(item) {
                this.pagination.current_page = item;
                this.$emit('change', item);
            }
        }
    }
</script>

<style lang="css" scoped>

    .pagination {
        justify-content: center;

    }

    .pagination .page-item {
        cursor: pointer;
    }

    .pagination .page-item .disabled {
        opacity: 0.5;
    }

    .pagination .page-item > span {
        box-shadow: none;
    }


</style>
