<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Templates</h3>
                            </div>
                            <p>Je kunt meerdere templates toevoegen zodat je documenten makkelijk kunt selecteren bij het starten van een ondertekening.</p>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-3 mb-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-md-9">
                        <div class="row">
                            <div class="d-flex align-items-center mb-3">
                                <h2 class="title-heading-left" style="">Templates</h2>
                            </div>

                            <div class="col-12 mb-4">
                                <label class="upload w-100">
                                    <span class="label_text">Upload hier PDF/JPG/PNG/DOC/DOCX bestand(en) om templates aan te maken</span>
                                    <input ref="file-input"
                                           accept="application/pdf,image/png,image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                           type="file"
                                           @change="addTemplateFile($event)"
                                           class=""
                                           placeholder="">
                                </label>
                            </div>

                            <SignableEmbed
                                v-if="templateToEdit"
                                :key="'template-edit-' + templateToEdit.id + '-' + templateToEdit.file.last_modified"
                                :signable="templateToEdit.file"
                                v-model="templateToEdit.options"
                                :signers="signersForTemplate"
                                :show-label="false"
                                :show-upload-text="false"
                                :save-button-text="'Opslaan'"
                                @save="saveTemplate"
                                @close="resetTemplateForm"
                            >
                                <template v-slot:above-variables>
                                    <div class="mb-3">
                                        <label>Template naam:</label>
                                        <input type="text" class="fusion-form-input" v-model="templateToEdit.template.name" placeholder="" name="Template naam">
                                    </div>
                                </template>
                            </SignableEmbed>

                            <div v-if="!templates.data.length && !loading" class="col-12 mt-5">
                                <div class="alert alert-info">
                                    Je hebt nog geen templates
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="template in templates.data" :key="template.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">{{ template.name }}</h2>
                                                    <div>
                                                        <small>Aangemaakt op: {{ template.created_at | moment('DD-MM-YYYY HH:mm') }}</small>
                                                    </div>
                                                </div>

                                                <button class="btn btn-sm btn-danger float-end" style="margin-left: 5px;" @click="templateToDelete = template">
                                                    <font-awesome-icon icon="trash"/>
                                                </button>

                                                <button class="btn btn-sm btn-primary float-end" @click="editTemplate(template)">
                                                    <font-awesome-icon icon="pencil-alt"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="templates" @change="getTemplates()"/>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Start delete modal -->
                <modal :show="templateToDelete" :closable="!deleteLoading" @close="templateToDelete = null">
                    <div slot="header">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Template verwijderen</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="templateToDelete = null"><span aria-hidden="true">×</span></button>
                        </div>
                    </div>

                    <div slot="body">
                        <div class="text-center" v-if="deleteLoading">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <p v-if="!deleteLoading">Weet je zeker dat je dit template wilt verwijderen?</p>
                    </div>

                    <div slot="footer" class="col-12">
                        <div style="padding-left: 0.5rem">
                            <button type="button" class="btn btn-danger me-2 float-start" @click="deleteTemplate()" :disabled="deleteLoading">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                Verwijderen
                            </button>
                            <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="templateToDelete = null">Sluiten</button>
                        </div>
                    </div>
                </modal>
                <!-- End delete modal -->
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from '@/components/SettingsMenu';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Modal from '../components/Modal';
    import {mapState} from 'vuex';
    import Pagination from '../components/Pagination';
    import SignableEmbed from '../components/SignableEmbed.vue';
    import addVariablesToFormData from '../utils/addVariablesToFormData';

    export default {

        components: {
            SignableEmbed,
            FullscreenLoading,
            SettingsMenu,
            Modal,
            Pagination,
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                templates: {
                    data: []
                },

                templateToDelete: null,
                deleteLoading: false,

                templateToEdit: null,

                signersForTemplate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(signer => {
                    return {
                        firstname: 'Ondertekenaar ' + signer,
                        lastname: '',
                        email: '',
                        verifications: [
                            {
                                type: 5,
                                text: 'Handtekening'
                            },
                        ]
                    };
                })
            };
        },

        computed: {
            ...mapState({
                company: state => state.company
            })
        },

        mounted() {
            this.getTemplates();
        },

        methods: {
            async getTemplates(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/templates', {
                        params: {
                            page: page || this.templates.current_page,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.templates = response.data;

                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            },

            async deleteTemplate() {
                this.deleteLoading = true;

                try {
                    await this.$http.delete(this.$root.api_url + '/api/templates/' + this.templateToDelete.id);

                    await this.getTemplates(1);

                    this.templateToDelete = null;
                } catch (exception) {
                    console.error(exception);
                }

                this.deleteLoading = false;
            },

            async editTemplate(template) {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.api_url + `/api/templates/${template.id}/file`, {
                        responseType: 'blob',
                        transformResponse: [function (data) {
                            return new window.Blob([data], {
                                type: 'application/pdf'
                            });
                        }]
                    });

                    const file = new File([response.data], template.id + '.pdf', {
                        type: 'application/pdf'
                    });
                    const filename = file.name.substring(0, file.name.lastIndexOf('.'));

                    this.templateToEdit = {
                        id: template.id,
                        file: {
                            showModal: true,
                            
                            file: file,
                            filename,
                            last_modified: file.lastModified,
                            size: file.size,
                            src: URL.createObjectURL(file),
                            config: {
                                variables: []
                            }
                        },

                        template: {...template},

                        options: {
                            variables: template.variables
                        }
                    };
                } catch (exception) {
                    console.error(exception);
                }

                this.loading = false;
            },

            async addTemplateFile(event) {
                this.loading = true;

                try {
                    const file = event.target.files[0];
                    const filename = file.name.substring(0, file.name.lastIndexOf('.'));

                    let formData = new FormData();

                    formData.append('file', file);

                    const response = await this.$http.post(this.$root.api_url + `/api/upload-file`, formData, {
                        responseType: 'blob',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        transformResponse: [function (data) {
                            return new window.Blob([data], {type: 'application/pdf'});
                        }]
                    });

                    const convertedFile = new File([
                        response.data
                    ], filename, {
                        type: 'application/pdf'
                    });

                    this.templateToEdit = {
                        id: 'create',


                        file: {
                            showModal: true,

                            file: convertedFile,
                            filename,
                            last_modified: convertedFile.lastModified,
                            size: convertedFile.size,
                            src: URL.createObjectURL(convertedFile),
                            config: {
                                variables: []
                            }
                        },

                        template: {
                            id: null,
                            name: filename
                        },

                        options: {
                            variables: []
                        }
                    };
                } catch (exception) {
                    console.error(exception);
                }

                this.loading = false;
            },

            resetTemplateForm() {
                this.templateToEdit = null;
            },

            async saveTemplate() {
                if (!this.templateToEdit.template.name) {
                    return;
                }

                this.loading = true;

                try {
                    if (this.templateToEdit.template.id) {
                        await this.$http.put(this.$root.api_url + '/api/templates/' + this.templateToEdit.template.id, {
                            name: this.templateToEdit.template.name,
                            variables: this.templateToEdit.options?.variables || []
                        });
                    } else {
                        let formData = new FormData();

                        formData.append('file', this.templateToEdit.file.file);
                        formData.append('name', this.templateToEdit.template.name);

                        addVariablesToFormData(formData, this.templateToEdit.options?.variables || []);

                        await this.$http.post(this.$root.api_url + '/api/templates', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                    }

                    await this.getTemplates();
                } catch (exception) {
                    console.error(exception);
                }

                this.resetTemplateForm();
                this.loading = false;
            }

        }
    };

</script>

<style scoped>

</style>