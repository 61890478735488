<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Webhook berichten</h3>
                            </div>
                            <p>
                                Indien je een webhook hebt ingesteld bij accountinstellingen vind je de berichten hier terug. Zo kan je eenvoudig controleren of welke berichten er zijn verstuurd met welke data. Toch nog vragen? Aarzel niet om contact met ons op te nemen!
                            </p>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-3 mb-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-md-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">Webhook berichten</h2>

                            <div v-if="!webhookRequests.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Je hebt nog geen webhook berichten. Stel een webhook in bij <router-link class="underline" :to="{name: 'AccountSettings'}">Account instellingen </router-link> om te starten met het gebruik van webhooks
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="webhookRequest in webhookRequests.data" :key="webhookRequest.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">Webhook <small>pogingen: {{ webhookRequest.attempts }}</small></h2>
                                                    <div>
                                                        <small>Datum: {{ webhookRequest.created_at | moment('DD-MM-YYYY HH:mm') }}</small>
                                                        <small v-if="webhookRequest.succeeded_at">, Voltooid op: {{ webhookRequest.succeeded_at | moment('DD-MM-YYYY HH:mm') }}</small>
                                                        <small v-if="webhookRequest.failed_at">, Gefaald op: {{ webhookRequest.failed_at | moment('DD-MM-YYYY HH:mm') }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12" v-for="(response, responseIndex) of webhookRequest.responses" :key="responseIndex">
                                                <span class="badge" :class="getBadgeColorForStatusCode(response.status_code)">{{ response.status_code }}</span> {{ webhookRequest.url }}
                                                <pre style="white-space: normal;" v-if="response.message">{{ response.message }}</pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="webhookRequests" @change="getWebhookRequests()"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from "@/components/SettingsMenu";
    import FullscreenLoading from "@/components/FullscreenLoading";
    import Pagination from '../components/Pagination.vue';

    export default {

        components: {
            Pagination,
            FullscreenLoading,
            SettingsMenu
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                webhookRequests: {
                    data: []
                }
            }
        },

        mounted() {
            this.getWebhookRequests();
        },

        methods: {
            async getWebhookRequests(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/webhook-requests', {
                        params: {
                            page: page || this.webhookRequests.current_page,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.webhookRequests = response.data;

                } catch (exception) {
                    this.errormessage = exception
                }

                this.loading = false;
            },

            getBadgeColorForStatusCode(status){
                if(status >= 200 && status < 400){
                    return 'bg-success'
                }

                if(status >= 400 && status < 500){
                    return 'bg-warning'
                }

                if(status >= 500){
                    return 'bg-error'
                }

                return 'bg-primary'
            }
        }
    }

</script>

<style scoped>

</style>