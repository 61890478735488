<template>
    <div>
        <div class="modal fade"
             :class="show ? 'show' : ''"
             tabindex="-1"
             role="dialog"
             aria-modal="true"
             :style="show ? 'display:block' : 'display:none'"
             ref="outerModal"
             @click="outerModalClick"
        >
            <div class="modal-dialog " role="document" :style="custom_dialog_css" :class="custom_modal_class" ref="modal">
                <div class="modal-content">
                    <slot name="header">
                        <!--no default content-->
                    </slot>

                    <div class="modal-body" ref="modalBody" :style="custom_body_css">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer" :class="custom_footer_class">
                        <slot name="footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Sluiten</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="show" class="modal-backdrop fade" @click="close" :class="show ? 'show' : ''"></div>
    </div>

</template>
<script>
    export default {

        props: {
            show: {},
            closable: {
                default: true
            },
            custom_dialog_css: {
                required: false,
                type: String,
            },
            custom_modal_class: {
                required: false,
                type: String,
            },
            custom_body_css: {
                required: false,
                type: String,
                default: ''//'max-height: 80vh'
            },
            custom_footer_class: {
                required: false,
                type: String,
                default: ''//'max-height: 80vh'
            }
        },

        data() {
            return {
                startCoordinates: {
                    x: null,
                    y: null
                }
            }
        },

        watch: {
            show() {
                this.modalArray(this.show);
            }
        },

        methods: {
            close() {
                if (!this.closable) {
                    return;
                }

                this.$emit('close');
            },

            outerModalClick($event) {
                if ($event.target === this.$refs.outerModal && this.startIsOutOfModal()) {
                    this.close();
                }
            },

            startIsOutOfModal() {
                if (this.startCoordinates.x === null && this.startCoordinates.y === null) {
                    return true;
                }

                const modalX = this.$refs.modal.offsetLeft;
                const modalY = this.$refs.modal.offsetTop;

                const modalWidth = this.$refs.modal.clientWidth;
                const modalHeight = this.$refs.modal.clientHeight;

                const startX = this.startCoordinates.x;
                const startY = this.startCoordinates.y;

                return !(modalX <= startX && startX <= (modalX + modalWidth) && modalY <= startY && startY <= (modalY + modalHeight));
            },

            keyDownEvent(event) {
                if (this.show && (event.keyCode === 27 || event.key === 'Escape')) {
                    this.close();
                }
            },

            mouseDownEvent(event) {
                this.startCoordinates = {
                    x: event.pageX,
                    y: event.pageY
                }
            },

            modalArray(state) {
                const modalId = this.$vnode.tag;

                if (state) {
                    this.$root.modals.push(modalId);

                    this.$emit('open');

                    setTimeout(() => {
                        this.$emit('opened');
                    }, 500);
                } else {
                    const modalIndex = this.$root.modals.indexOf(modalId);

                    if (modalIndex !== -1) {
                        this.$root.modals.splice(modalIndex);
                    }
                }
            },

            scrollEvent(event) {
                this.$emit('scroll', event)
            }
        },

        mounted() {
            document.addEventListener('keydown', this.keyDownEvent);
            document.addEventListener('mousedown', this.mouseDownEvent);
            this.$refs.outerModal.addEventListener('scroll', this.scrollEvent);

            this.modalArray(this.show);
        },

        beforeDestroy() {
            this.modalArray(false);
            this.$refs.outerModal.removeEventListener('scroll', this.scrollEvent);
        },

        destroyed() {
            window.removeEventListener('keydown', this.keyDownEvent);
            window.removeEventListener('mousedown', this.mouseDownEvent);
        }
    }
</script>


<style>
    .fade {
        opacity: 1;
    }
</style>