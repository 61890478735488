<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Facturen</h3>
                            </div>
                            <p>
                                Hier vind je alle facturen terug die in het systeem zijn gegenereerd.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">Facturen</h2>

                            <div v-if="!invoices.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Er zijn nog geen facturen
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="invoice in invoices.data" :key="invoice.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">Factuur <small>{{ invoice.invoice_no }}</small></h2>
                                                    <div><small>Datum: {{ invoice.invoice_date }}</small></div>
                                                    <div><small>Bedrijfsnaam: {{ invoice.company.name }}</small></div>
                                                </div>
                                                <a :download="'Signly factuur ' + invoice.invoice_no + '.pdf'" :href="`/api/all-invoices/${invoice.id}/download?token=` + token" class="btn btn-sm btn-primary float-end">
                                                    <font-awesome-icon icon="download"/>
                                                </a>
                                            </div>
                                            <div class="col-12" v-for="invoiceLine of invoice.invoice_lines" :key="invoiceLine.id">
                                                {{ invoiceLine.description }}
                                                <span class="float-end">&euro; {{ (invoiceLine.value * invoiceLine.amount).toFixed(2).replace('.', ',') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="invoices" @change="getInvoices()"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from '@/components/SettingsMenu';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Pagination from '../../components/Pagination';
    import {mapState} from 'vuex';

    export default {

        components: {
            FullscreenLoading,
            SettingsMenu,
            Pagination
        },

        computed: mapState({
            token: state => state.token
        }),

        data() {
            return {
                loading: false,
                order: 'id,desc',
                invoices: {
                    data: []
                }
            };
        },

        mounted() {
            this.getInvoices();
        },

        methods: {
            async getInvoices(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/all-invoices', {
                        params: {
                            page: page || this.invoices.current_page,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.invoices = response.data.invoices;
                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            }
        }
    };

</script>

<style scoped>

</style>
