<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Aangepaste abonnementen</h3>
                            </div>
                            <p>
                                Hier vind je alle aangepaste abonnementen terug die beschikbaar zijn in het systeem.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-3">
                        <button class="btn btn-primary w-100 mb-3" @click="paymentPlanToSave = {}">
                            Nieuw aangepast abonnement
                        </button>

                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">Aangepaste abonnementen</h2>

                            <div v-if="!paymentPlans.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Er zijn nog geen aangepaste abonnementen
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="paymentPlan in paymentPlans.data" :key="paymentPlan.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">{{ paymentPlan.name }} - <small>{{ paymentPlan.price.price | formatCurrency }} per maand</small></h2>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 mt-2">
                                                        <h5 class="h3-card-header">Prijs afspraken:</h5>
                                                        <small class="d-block">Credits per maand: {{ paymentPlan.price.credits_per_month }}</small>
                                                        <small class="d-block">Prijs per SMS: {{ getVerificationPrice(paymentPlan, 'sms', 'gratis') }}</small>
                                                        <small class="d-block">Prijs per iDIN: {{ getVerificationPrice(paymentPlan, 'idin', 'gratis') }}</small>
                                                    </div>

                                                    <div class="col-12 mt-2" v-if="paymentPlan.available_for_companies.length">
                                                        <h5 class="h3-card-header">Beschikbaar voor bedrijven:</h5>
                                                        <small class="small d-block" v-for="company in paymentPlan.available_for_companies" :key="company.id">
                                                            {{ company.name }}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="paymentPlans" @change="getPaymentPlans()"/>
                            </div>
                        </div>
                    </div>

                </div>

                <ValidationObserver ref="savePaymentPlan" v-slot="{ invalid }" tag="form">
                    <modal :show="paymentPlanToSave" :closable="!paymentPlanLoading" @close="paymentPlanToSave = null" :custom_modal_class="'modal-lg'">
                        <div slot="header">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0">Abonnement {{ paymentPlanToSave && paymentPlanToSave.id ? 'bewerken' : 'aanmaken' }}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="paymentPlanToSave = null"><span aria-hidden="true">×</span></button>
                            </div>
                        </div>

                        <div slot="body">
                            <div v-if="paymentPlanToSave">
                                <div class="form-group mb-3">
                                    <label class="form-control-label">Naam: <sup>*</sup></label>
                                    <validation-provider vid="name" rules="required" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="paymentPlanToSave.name" placeholder="" name="name">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Beschikbaar voor: <sup>*</sup></label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <multiselect
                                            v-model="paymentPlanToSave.available_for_companies"
                                            :options="companies"
                                            label="name"
                                            track-by="id"
                                            :multiple="true"
                                            :selectLabel="'Klik om te selecteren'"
                                            :deselectLabel="'Klik om te verwijderen'"
                                            :selectedLabel="'Geselecteerd'"
                                            :placeholder="'Selecteerd bedrijven'"
                                            :searchable="false">
                                        </multiselect>
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Prijs per maand (ex btw): <sup>*</sup></label>
                                    <validation-provider vid="price_per_month" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.price_per_month" placeholder="" name="price_per_month">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Prijs per prepaid credit (ex btw): <sup>*</sup></label>
                                    <validation-provider vid="price_per_credit" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.price_per_credit" placeholder="" name="price_per_credit">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Credits per maand: <sup>*</sup></label>
                                    <validation-provider vid="credits_per_month" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.credits_per_month" placeholder="" name="credits_per_month">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <h5>SMS verificatie</h5>
                                <div class="form-group mb-3">
                                    <label class="form-control-label">Prijs per SMS (ex btw): <sup>*</sup></label>
                                    <validation-provider vid="sms_euros_price" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.sms_euros_price" placeholder="" name="sms_euros_price">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Credits per SMS: <sup>*</sup></label>
                                    <validation-provider vid="sms_credits_price" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.sms_credits_price" placeholder="" name="sms_credits_price">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <h5>iDIN verificatie</h5>
                                <div class="form-group mb-3">
                                    <label class="form-control-label">Prijs per iDIN transactie (ex btw): <sup>*</sup></label>
                                    <validation-provider vid="idin_euros_price" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.idin_euros_price" placeholder="" name="idin_euros_price">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Credits per iDIN transactie: <sup>*</sup></label>
                                    <validation-provider vid="idin_credits_price" rules="required" v-slot="{ errors }">
                                        <input type="number" step="0.01" class="fusion-form-input" v-model.number="paymentPlanToSave.idin_credits_price" placeholder="" name="idin_credits_price">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>

                        <div slot="footer" class="col-12">
                            <div style="padding-left: 0.5rem">
                                <button type="button" class="btn btn-success me-2 float-start" @click="savePaymentPlan" :disabled="paymentPlanLoading || invalid">
                                    {{ paymentPlanToSave && paymentPlanToSave.id ? 'Opslaan' : 'Aanmaken' }}
                                </button>
                                <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="paymentPlanToSave = null">Sluiten</button>
                            </div>
                        </div>
                    </modal>
                </ValidationObserver>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from '@/components/SettingsMenu';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Pagination from '../../components/Pagination';
    import Modal from '../../components/Modal.vue';
    import {ValidationObserver, ValidationProvider} from 'vee-validate';

    export default {

        components: {
            Modal,
            FullscreenLoading,
            SettingsMenu,
            Pagination,
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                loading: false,
                paymentPlans: {
                    data: []
                },

                companies: [],

                paymentPlanToSave: null,
                paymentPlanLoading: false
            };
        },

        mounted() {
            this.getPaymentPlans();
        },

        methods: {
            async getPaymentPlans(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/all-payment-plans', {
                        params: {
                            page: page || this.paymentPlans.current_page,
                        }
                    });

                    this.companies = response.data.companies;
                    this.paymentPlans = response.data.payment_plans;

                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            },

            getVerificationPrice(paymentPlan, type, defaultValue) {
                const pricing = [];
                const credits = paymentPlan.price[type + '_credits_price'] || null;
                const euros = paymentPlan.price[type + '_euros_price'] || null;

                if (credits) {
                    pricing.push(`${credits} credits`);
                }

                if (euros) {
                    pricing.push(`€ ${euros}`);
                }

                if (!pricing.length) {
                    return defaultValue;
                }

                return pricing.filter(Boolean).join(' en ');
            },

            async savePaymentPlan() {
                this.paymentPlanLoading = true;

                try {
                    const data = {
                        ...this.paymentPlanToSave,
                        available_for_companies: (this.paymentPlanToSave.available_for_companies || []).map(company => company.id)
                    };

                    await this.$http.post(this.$root.api_url + '/api/all-payment-plans/' + (this.paymentPlanToSave.id ? this.paymentPlanToSave.id : 'create'), data);

                    await this.getPaymentPlans(1);

                    this.paymentPlanToSave = null;
                } catch (exception) {
                    if (exception?.response?.data?.errors) {
                        this.$refs.savePaymentPlan.setErrors(exception?.response?.data?.errors);
                    }
                }

                this.paymentPlanLoading = false;
            }
        }
    };

</script>
