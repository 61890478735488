<template>
    <div>
        <div>
            <div class="home mt-5">

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Contact & support</h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-3 mb-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-md-9">
                        <div class="row">

                            <div class="col-8">
                                <h2 class="title-heading-left mb-3" style="">Contact & support</h2>
                                <p>
                                    Heb je een vraag of opmerking? Wellicht een goede aanvulling op onze dienstverlening? Neem gerust contact met ons op via <a href="mailto:info@signly.nl">info@signly.nl</a> of het contact formulier op <a href="https://signly.nl/neem-contact-op/" target="_blank">www.signly.nl</a>.
                                </p>
                                <p>
                                    <strong>Signly</strong> is een product van 302 online applicaties
                                </p>
                            </div>

                            <div class="col-4 mb-3">
                                <div class="summary-box p-3">
                                    <h2 class="title-heading-left mb-3" style="">Contact gegevens</h2>

                                    Lieskes Wengs 3A<br/>
                                    6578 JK, Leuth<br/>
                                    KVK: 67881696<br/>
                                    <br/>
                                    <a href="mailto:info@signly.nl">info@signly.nl</a><br/>
                                    <a href="tel:024 711 0307">024 711 0307</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from "@/components/SettingsMenu";

    export default {

        components: {
            SettingsMenu
        },
    }

</script>

<style scoped>

</style>