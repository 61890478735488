<template>
    <div>
        <div>
            <div class="home mt-5">
                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Bedankt voor jouw bestelling</h3>
                            </div>
                            <p>
                                Bedankt voor jouw bestelling bij Signly!  Mocht je nog vragen hebben aarzel dan niet om contact met ons op te nemen via Chat of <a class="underline" href="mailto:info@signly.nl">email</a>. Indien de betaling is gelukt kan je gelijk starten met het aanmaken van een <router-link class="underline" :to="{name: 'NewSigning'}">nieuwe ondertekening</router-link>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

    }

</script>

<style scoped>

</style>