<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Gebruikers</h3>
                            </div>
                            <p>Je kunt meerdere gebruikers toevoegen aan jouw bedrijf. Gebruikers hebben toegang tot alle ondertekeningen van jouw bedrijf.</p>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-3 mb-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-md-9">
                        <div v-if="!hasFeature(company, 'users')" class="alert alert-info">
                            <p>Om meerdere gebruikers toe te voegen heb je een standaard of premium abonnement nodig.</p>

                            <div class="text-center mt-2">
                                <router-link :to="'/instellingen/credits'">
                                    <button class="btn btn-primary">Abonnement wijzigen</button>
                                </router-link>
                            </div>
                        </div>

                        <div v-else class="row">
                            <div class="d-flex align-items-center mb-3">
                                <h2 class="title-heading-left" style="">Gebruikers</h2>

                                <div style="margin-left: auto" v-if="canCreateUser()">
                                    <button class="btn btn-primary" @click="userToSave = {}">Gebruiker aanmaken</button>
                                </div>
                            </div>

                            <div v-if="!users.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Je hebt nog geen gebruikers
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="user in users.data" :key="user.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">{{ user.name }} - <small>{{ user.email }}</small></h2>
                                                    <div>
                                                        <small>Aangemaakt op: {{ user.created_at | moment('DD-MM-YYYY HH:mm') }}</small>
                                                    </div>
                                                </div>

                                                <button class="btn btn-sm btn-danger float-end" style="margin-left: 5px;" v-if="user.id !== currentUser.id && users.total > 1" @click="userToDelete = user">
                                                    <font-awesome-icon icon="trash"/>
                                                </button>

                                                <button class="btn btn-sm btn-primary float-end" @click="userToSave = user">
                                                    <font-awesome-icon icon="pencil-alt"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="users" @change="getUsers()"/>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Start delete modal -->
                <modal :show="userToDelete" :closable="!deleteLoading" @close="userToDelete = null">
                    <div slot="header">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Gebruiker verwijderen</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                    </div>

                    <div slot="body">
                        <div class="text-center" v-if="deleteLoading">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <p v-if="!deleteLoading">Weet je zeker dat je deze gebruiker wilt verwijderen?</p>
                    </div>

                    <div slot="footer" class="col-12">
                        <div style="padding-left: 0.5rem">
                            <button type="button" class="btn btn-danger me-2 float-start" @click="deleteUser()" :disabled="deleteLoading">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                                Verwijderen
                            </button>
                            <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="userToDelete = null">Sluiten</button>
                        </div>
                    </div>
                </modal>
                <!-- End delete modal -->

                <ValidationObserver ref="saveUser" v-slot="{ invalid }" tag="form">

                    <!-- Start create modal -->
                    <modal :show="userToSave" :closable="!saveUserLoading" @close="userToSave = null" :custom_modal_class="'modal-lg'">
                        <div slot="header">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0">Gebruiker {{ userToSave && userToSave.id ? 'bewerken' : 'aanmaken' }}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            </div>
                        </div>

                        <div slot="body">
                            <div v-if="userToSave">
                                <div class="form-group mb-3">
                                    <label class="form-control-label">Naam: <sup>*</sup></label>
                                    <validation-provider vid="name" rules="required" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="userToSave.name" placeholder="" name="name">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-control-label">Email: <sup>*</sup></label>
                                    <validation-provider vid="email" rules="required|email" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="userToSave.email" placeholder="" name="email">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>

                                <div class="form-group mb-3" v-if="!userToSave.id">
                                    <label class="form-control-label">Wachtwoord: <sup>*</sup></label>
                                    <div class="">
                                        <validation-provider vid="password" rules="required" v-slot="{ errors }">
                                            <input :disabled="loading" type="password" v-model="userToSave.password" class="form-control" id="password" name="password" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div slot="footer" class="col-12">
                            <div style="padding-left: 0.5rem">
                                <button type="button" class="btn btn-success me-2 float-start" @click="saveUser()" :disabled="saveUserLoading || invalid">
                                    {{ userToSave && userToSave.id ? 'Opslaan' : 'Aanmaken' }}
                                </button>
                                <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="userToSave = null">Sluiten</button>
                            </div>
                        </div>
                    </modal>
                    <!-- End create modal -->
                </ValidationObserver>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from '@/components/SettingsMenu';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Modal from '../components/Modal';
    import {mapState} from 'vuex';
    import Pagination from '../components/Pagination';
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {email, required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    extend('email', {
        ...email,
        message: 'Dit veld is dient een emailadres te zijn'
    });

    export default {

        components: {
            FullscreenLoading,
            SettingsMenu,
            Modal,
            Pagination,
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                users: {
                    data: []
                },

                userToDelete: null,
                deleteLoading: false,

                userToSave: null,
                saveUserLoading: false,
            }
        },

        computed: {
            ...mapState({
                company: state => state.company,
                currentUser: state => state.user
            })
        },

        mounted() {
            this.getUsers();
        },

        methods: {
            canCreateUser(){
                const maxUsers = this.company?.current_payment_plan?.price?.users || null;

                return this.users.total < (maxUsers === null ? Infinity : maxUsers);
            },

            async getUsers(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/users', {
                        params: {
                            page: page || this.users.current_page,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.users = response.data;

                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            },

            async saveUser() {
                this.saveUserLoading = true;

                try {
                    if (this.userToSave.id) {
                        await this.$http.put(this.$root.api_url + '/api/users/' + this.userToSave.id, this.userToSave);
                    } else {
                        await this.$http.post(this.$root.api_url + '/api/users', this.userToSave);
                    }

                    await this.getUsers(1);

                    this.userToSave = null;
                } catch (exception) {
                    if (exception?.response?.data?.errors) {
                        this.$refs.saveUser.setErrors(exception?.response?.data?.errors);
                    }
                }

                this.saveUserLoading = false;
            },

            async deleteUser() {
                this.deleteLoading = true;

                try {
                    await this.$http.delete(this.$root.api_url + '/api/users/' + this.userToDelete.id);

                    await this.getUsers(1);

                    this.userToDelete = null;
                } catch (exception) {
                    console.error(exception)
                }

                this.deleteLoading = false;
            }

        }
    }

</script>

<style scoped>

</style>