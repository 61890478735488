(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        langs: {
            nl: {
                mergefields: 'Mergevelden'
            },
            en: {
                mergefields: 'Mergevelden'
            }
        }
    });

    var defaultOptions = {
        mergefields: []
    };

    $.extend(true, $.trumbowyg, {
        plugins: {
            mergefields: {
                init: function (trumbowyg) {
                    trumbowyg.o.plugins.mergefields = trumbowyg.o.plugins.mergefields || defaultOptions;

                    trumbowyg.addBtnDef('mergefields', {
                        dropdown: buildDropdown(trumbowyg),
                        hasIcon: false,
                        text: trumbowyg.lang.mergefields,
                    });
                }
            }
        }
    });

    function buildDropdown(trumbowyg) {
        var dropdown = [];

        $.each(trumbowyg.o.plugins.mergefields.mergefields, function (index, mergefield) {
            trumbowyg.addBtnDef('merge_field_' + index, {
                title: mergefield,
                hasIcon: false,
                fn: function () {
                    trumbowyg.execCmd('insertText', mergefield, true);
                }
            });
            dropdown.push('merge_field_' + index);
        });

        return dropdown;
    }
})(window.jQuery);
