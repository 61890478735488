import {mapState} from 'vuex';

export default {


    data() {
        return {
            states: [
                {'id': 1, 'name': 'Aangemaakt', 'description': 'Resource created', searchable: true, 'type': 3, 'class': 'bg-primary'},
                {'id': 2, 'name': 'Afgerond', 'description': 'Verification is done', searchable: true, 'type': 3, 'class': 'bg-success'},
                {'id': 3, 'name': 'Bezig', 'description': 'Resource in progress', searchable: true, 'type': 3, 'class': 'bg-info'},
                {'id': 4, 'name': 'Geweigerd', 'description': 'Resource declined', searchable: true, 'type': 2, 'class': 'bg-danger'},
                {'id': 5, 'name': 'Getekend', 'description': 'Resource approved', searchable: true, 'type': 3, 'class': 'bg-success'},
                {'id': 6, 'name': 'Geen herinneringen meer', 'description': 'Signer has no reminders left', 'type': 2, 'class': 'bg-warning'},
                {'id': 7, 'name': 'Verlopen', 'description': 'Resource expired', searchable: true, 'type': 2, 'class': 'bg-danger'},
                {'id': 8, 'name': 'Geannuleerd', 'description': 'Cancelled', searchable: true, 'type': 2, 'class': 'bg-danger'},
                {'id': 9, 'name': 'Mislukt', 'description': 'Something went wrong while processing', searchable: true, 'type': 1, 'class': 'bg-danger'},
                {'id': 40, 'name': 'Kan geen melding versturen', 'description': 'Cannot send notification', 'type': 1, 'class': 'bg-warning'},
                {'id': 50, 'name': 'Ongeldig', 'description': 'Resource is invalid', searchable: true, 'type': 1, 'class': 'bg-danger'},
                {'id': 51, 'name': 'Bestand ongeldig', 'description': 'Resource has invalid file', 'type': 1, 'class': 'bg-danger'},
                {'id': 60, 'name': 'Behandeld door ondertekenaars', 'description': 'Resource is handled by all signers', 'type': 3, 'class': 'bg-info'}
            ],
            mixin_emailtemplates: [
                {
                    name: 'sign_request_to_signer',
                    template: '<p>Beste [[name]]</p><p> We hebben een document klaar gezet ter ondertekening. Klik de onderstaande link om deze tekenen.</p><p>[[content]]</p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Document(en) digitaal ondertekenen',
                    merge_fields: [
                        'content',
                        'sign_request.hash',
                        'signer.name',
                        'signer.firstname',
                        'signer.lastname',
                        'signer.hash',
                        'department.name',
                        'company.name',
                        'name',
                        'link'
                    ]
                },
                {
                    name: 'sign_request_to_signer_reminder',
                    template: '<p>Beste [[name]]</p><p> We hebben een document klaar gezet ter ondertekening die nog niet getekend is. Klik de onderstaande link om deze tekenen.</p><p>[[content]]</p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Document(en) digitaal ondertekenen',
                    merge_fields: [
                        'content',
                        'sign_request.hash',
                        'signer.name',
                        'signer.firstname',
                        'signer.lastname',
                        'signer.hash',
                        'department.name',
                        'company.name',
                        'name',
                        'link'
                    ]
                },
                {
                    name: 'signables_to_signer',
                    template: '<p>Beste [[name]]</p><p>Je digitaal ondertekend(e) document(en) vind je als bijlage in deze email. Bewaar deze op een veilige plek.</p><p>[[content]]</p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Je digitaal ondertekend(e) document(en)',
                    merge_fields: [
                        'sign_request.hash',
                        'signer.name',
                        'signer.firstname',
                        'signer.lastname',
                        'signer.hash',
                        'department.name',
                        'company.name',
                        'name',
                    ]
                },
                {
                    name: 'signables_to_sign_request',
                    template: '<p>Beste [[name]]</p><p> Eén van jouw ondertekeningen is succesvol ondertekend. Je vind de getekend(e) document(en) in de bijlage van deze email. Bewaar deze op een veilige plek</p><p>[[content]]</p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Je digitaal ondertekend(e) document(en)',
                    merge_fields: [
                        'sign_request.hash',
                        'department.name',
                        'company.name',
                        'name',
                    ]
                },
                {
                    name: 'email_verification',
                    template: '<p>Beste [[name]]</p><p> Klik op de onderstaande link om jouw email adres te valideren. </p><p>[[content]]</p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Email verificatie ondertekenen',
                    merge_fields: [
                        'content',
                        'sign_request.hash',
                        'signer.name',
                        'signer.firstname',
                        'signer.lastname',
                        'signer.hash',
                        'department.name',
                        'company.name',
                        'name',
                        'link',
                    ]
                },
                {
                    name: 'sign_request_cancelled_to_signer',
                    template: '<p>Beste [[name]]</p><p> De ondertekening is geannuleerd. </p><p>Met vriendelijke groet,</p> <p> [[company.name]]</p>',
                    subject: 'Ondertekening geannuleerd',
                    merge_fields: [
                        'content',
                        'sign_request.hash',
                        'signer.name',
                        'signer.firstname',
                        'signer.lastname',
                        'signer.hash',
                        'department.name',
                        'company.name',
                        'name',
                    ]
                },
            ]
        };
    },

    computed: {
        ...mapState({
            company: state => state.company
        }),

        verifications() {
            const verifications = [
                {
                    type: 5,
                    text: 'Handtekening'
                },
                {
                    type: 6,
                    text: 'Paraaf'
                },
                {
                    type: 2,
                    text: 'SMS'
                },
                // {
                //     type: 3,
                //     text: 'DigiID'
                // },
                {
                    type: 1,
                    text: 'Email'
                },
            ];

            if (this.company && this.hasFeature(this.company, 'idin_verification')) {
                verifications.push({
                    type: 4,
                    text: 'iDIN'
                });
            }

            return verifications;
        },
    },

    methods: {

        getStateNameFromID(state_id) {

            const current_state = this.states.find(state => {
                return state.id === state_id;
            });

            return current_state.name;
        },

        getVerificationTypeFromNumber(type_id) {

            const current_state = this.verifications.find(type => {
                return type.type === type_id;
            });

            return current_state.text;
        },

        getStateClassFromID(state_id) {

            const current_state = this.states.find(state => {
                return state.id === state_id;
            });

            return current_state.class;
        }
    }
};