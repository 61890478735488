var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid d-flex flex-column"},[_c('div',{staticClass:"row justify-content-center min-vh-100"},[_c('div',{staticClass:"col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5"},[_c('div',{staticClass:"card",staticStyle:{"max-width":"600px","margin":"0 auto"}},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',[_c('span',{staticClass:"clearfix"}),_c('ValidationObserver',{ref:"register",attrs:{"tag":"form"}},[_c('form',[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Bedrijfsnaam: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.company),expression:"request.company"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"text","id":"company","name":"company","placeholder":""},domProps:{"value":(_vm.request.company)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "company", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Naam: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.name),expression:"request.name"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"text","id":"name","name":"name","placeholder":""},domProps:{"value":(_vm.request.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "name", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Emailadres: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.email),expression:"request.email"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"email","id":"email","name":"email","placeholder":""},domProps:{"value":(_vm.request.email)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "email", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.emailError))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Telefoonnummer:")]),_c('div',{},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.phone),expression:"request.phone"}],staticClass:"form-control input-text",attrs:{"disabled":_vm.loading,"rules":"maxlength:50","type":"tel","id":"phone","name":"phone","placeholder":""},domProps:{"value":(_vm.request.phone)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "phone", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Wachtwoord: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.password),expression:"request.password"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"password","id":"password","name":"password","placeholder":""},domProps:{"value":(_vm.request.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "password", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Wachtwoord herhalen: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.passwordconfirmation),expression:"request.passwordconfirmation"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"password","id":"passwordconfirmation","name":"password","placeholder":""},domProps:{"value":(_vm.request.passwordconfirmation)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "passwordconfirmation", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('validation-provider',{attrs:{"rules":"terms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.terms),expression:"request.terms"}],staticClass:"custom-checkbox",attrs:{"type":"checkbox","name":"terms","id":"terms"},domProps:{"checked":Array.isArray(_vm.request.terms)?_vm._i(_vm.request.terms,null)>-1:(_vm.request.terms)},on:{"change":function($event){var $$a=_vm.request.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.request, "terms", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.request, "terms", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.request, "terms", $$c)}}}}),_c('label',{staticClass:"form-control-label",attrs:{"for":"terms"}},[_vm._v(" Ik ga akkoord met de "),_c('a',{attrs:{"href":_vm.termsLink,"target":"_blank"}},[_vm._v("algemene voorwaarden")])]),_c('small',{staticClass:"error d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.errormessage)?_c('div',{staticClass:"alert alert-danger mt-2"},[_vm._v(_vm._s(_vm.errormessage))]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('button',{staticClass:"btn btn-block btn-primary btn-caps",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.register}},[_vm._v("Registreer")])]),_c('div',{staticClass:"text-center form-group mt-3"},[_c('small',[_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Inloggen")])],1)])])])],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 mt-5 text-center"},[_c('h3',{staticClass:"h3-green",staticStyle:{"font-size":"40px"}},[_vm._v("Registreren")]),_c('p',[_c('strong',[_vm._v("Maak een account aan en ontvang gratis 10 credits!")])])])}]

export { render, staticRenderFns }