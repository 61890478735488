export function isLocalStorageEnabled() {
    try {
        localStorage.getItem('test');

        return true;
    } catch (exception) {
        return false;
    }
}

export function getLocalStorageLocaleKey() {
    return 'currentLocale';
}

export function getLocalStorageTokenKey() {
    return 'token';
}

