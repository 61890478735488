<template>
    <div>
        <div class="home mt-5">

            <fullscreen-loading v-if="loading"></fullscreen-loading>

            <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div class="row">
                    <div class="col-12">
                        <div class="fusion-title">
                            <h3 class="h3-green" style="font-size:50px;">Credits & Abonnementen</h3>
                        </div>
                        <p>
                            Bekijk hieronder je huidige abonnement, koop credits of upgrade je abonnement.
                        </p>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-3 mb-3">
                    <settings-menu></settings-menu>
                </div>

                <div class="col-md-9">

                    <div class="row mb-4">

                        <div class="col-6">
                            <div v-if="hasFeature(company, 'buy_credits')">
                                <h2 class="title-heading-left ">Credits aanschaffen <sup v-tooltip="'Koop extra credits in, deze credits blijven onbeperkt geldig.'">?</sup></h2>

                                <ValidationObserver ref="buy_credits" v-slot="{ invalid }" tag="form" class="mt-3">
                                    <label>Credits: <sup>*</sup></label>

                                    <validation-provider rules="required|min:1|max:2500" v-slot="{ errors }">
                                        <input type="number" class="fusion-form-input" v-model="buyCreditsData.amount" placeholder="">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                    <button class="btn btn-success float-end btn-caps mt-3" type="button" @click="buyCredits()" :disabled="invalid">
                                        <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                        Kopen
                                    </button>
                                </ValidationObserver>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <h2 class="title-heading-left mb-4 ">Abonnementen</h2>

                        <div v-if="company.next_payment_date">
                            <div class="alert alert-info">Op {{ company.next_payment_date | moment('D MMMM YYYY') }} worden er nieuwe credits bijgeschreven en een automatische betaling gedaan.</div>
                        </div>

                        <div class="col-12 col-sm-4 mb-4" v-for="paymentPlan of paymentPlans" :key="paymentPlan.id">
                            <div class="summary-box h-100 p-3 panel-wrapper fusion-column column fusion-pricingtable-column">
                                <div class="panel-container">
                                    <div class="fusion-panel">
                                        <div>
                                            <span v-if="activePlan(paymentPlan)" class="badge bg-success position-absolute" style="right:40px;top:20px;">Actief </span>
                                        </div>
                                        <div>
                                            <span v-if="isNextPlan(paymentPlan)" class="badge bg-info position-absolute" style="right:40px;top:20px;">Gaat in op {{ nextPlan.valid_from | moment('D MMMM YYYY') }} </span>
                                        </div>
                                        <div class="panel-heading">

                                            <h3 class="title-row text-center fusion-responsive-typography-calculated">{{ paymentPlan.name }} </h3>
                                        </div>

                                        <div class="panel-body pricing-row">
                                            <div class="price text-center mb-1">
                                                <h3 class="h3-green text-center" style="font-size:35px;">{{ paymentPlan.price.price | formatCurrency }}</h3>
                                                <span v-if="paymentPlan.price.type === 'prepaid'">Per credit</span>
                                                <span v-else-if="paymentPlan.price.type === 'month'">Per maand</span>
                                            </div>
                                        </div>

                                        <ul class="list-group">
                                            <li class="list-group-item normal-row text-center" v-for="(feature, featureIndex) of getPaymentPlanFeatures(paymentPlan)" :key="featureIndex">
                                                {{ feature }}
                                            </li>
                                        </ul>
                                        <div class="panel-footer footer-row mt-2 text-center">
                                            <button class="btn btn-success btn-caps " @click="togglePayModal(paymentPlan)" :disabled="paymentPlan.id === company.payment_plans[0].id">Selecteren</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div class="small mb-2" style="color: #898989; line-height: 17px">
                            De genoemde prijzen zijn exclusief BTW.<br/>
                            Per ondertekenaar wordt er 1 credit in rekening gebracht.
                        </div>
                    </div>

                    <modal :show="paymentPlayChangeModal" :closable="!loading" @close="paymentPlayChangeModal = null">
                        <div slot="header">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0">Abonnement aanschaffen</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            </div>
                        </div>

                        <div slot="body">
                            <div class="text-center" v-if="loading || requiresFirstPaymentLoading">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            <div v-if="!requiresFirstPaymentLoading && paymentPlayChangeModal">
                                <p>Weet je zeker dat je dit abonnement wilt aanschaffen? Dit abonnement zal ingaan op {{ getNewPlanDate() }}.</p>

                                <div v-if="paymentPlayChangeModal.price.type === 'month'">
                                    <p>Aangegeven credits worden gelijk beschikbaar gesteld. De facturatie/incassering zal aan het einde van de periode plaatsvinden. Eventuele kosten van iDIN en SMS zullen maandelijks achteraf worden berekend.</p>

                                    <p v-if="requiresFirstPayment">Om de automatische incasso te machtigen is vooraf een eenmalige betaling van € 0,01 vereist.</p>
                                </div>
                            </div>
                        </div>

                        <div slot="footer" class="col-12">
                            <div style="padding-left: 0.5rem">
                                <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="paymentPlayChangeModal = false">Sluiten</button>
                                <button type="button" class="btn btn-primary me-2 float-start" @click="selectPaymentPlan(paymentPlayChangeModal)" :disabled="loading || requiresFirstPaymentLoading">
                                    <span v-if="requiresFirstPayment">Incasso starten en aanschaffen</span>
                                    <span v-else>Aanschaffen</span>
                                </button>
                            </div>
                        </div>
                    </modal>


                    <div class="mt-5 mb-5">
                        <h2 class="title-heading-left mt-4 mb-4">Betaal historie</h2>

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Naam</th>
                                    <th>Geldig vanaf</th>
                                    <th>Prijs</th>
                                    <th style="text-align: right">Actie</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="plan in company.payment_plans" :key="plan.id + plan.valid_from">
                                    <td>{{ plan.id }}</td>
                                    <td>{{ plan.name }}</td>
                                    <td>{{ plan.valid_from | moment('D MMMM YYYY') }}</td>
                                    <td>
                                        {{ plan.price.price | formatCurrency }}
                                        <span v-if="plan.price.type === 'prepaid'">per credit</span>
                                        <span v-if="plan.price.type === 'month'">per maand</span>
                                    </td>
                                    <td style="text-align: right">
                                        <button class="btn btn-danger btn-sm" v-tooltip="'Abonnement annuleren'" @click="cancelPlan(plan)" v-if="canCancelPlan(plan)">
                                            <font-awesome-icon icon="eject"></font-awesome-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import FullscreenLoading from '@/components/FullscreenLoading';
    import SettingsMenu from '@/components/SettingsMenu';
    import {mapState} from 'vuex';
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {max, min, required} from 'vee-validate/dist/rules';
    import moment from 'moment';
    import Modal from '@/components/Modal';
    import {formatCurrency} from '../main';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    extend('max', {
        ...max,
        validate(value, args) {
            return value <= Number(args.length);
        },
        params: ['length'],
        message: 'De waarde in dit veld is teveel'
    });

    extend('min', {
        ...min,
        validate(value, args) {
            console.log(value, args);
            return value >= Number(args.length);
        },
        params: ['length'],
        message: 'De waarde in dit veld is te weinig'
    });

    export default {

        name: 'Credits',

        components: {
            Modal,
            SettingsMenu,
            FullscreenLoading,
            ValidationProvider,
            ValidationObserver,
        },

        computed: {
            ...mapState({
                company: state => state.company
            }),

            nextPlan() {
                const planInFuture = [...this.company.payment_plans].reverse().find(paymentPlan => {
                    return moment(paymentPlan.valid_from).isAfter(moment(this.company.current_payment_plan.valid_from));
                });

                if (planInFuture) {
                    return planInFuture;
                }

                return null;
            }
        },

        data() {
            return {
                order_loading: false,
                loading: false,
                paymentPlayChangeModal: false,

                featureTranslations: {
                    webhooks: 'Webhooks',
                    signers: 'Meerdere ondertekenaars',
                    documents: 'Meerdere documenten',
                    departments: 'Meerdere afdelingen',
                    api: 'API',
                    styling: 'Eigen huisstijl',
                    input_fields: 'Invulvelden',
                },

                paymentPlans: [],

                buyCreditsData: {
                    amount: 10
                },

                requiresFirstPayment: false,
                requiresFirstPaymentLoading: false
            };
        },

        mounted() {
            this.getPaymentPlans();
        },

        methods: {
            canCancelPlan(plan) {
                return moment(plan.valid_from).isAfter(moment(), 'day');
            },

            async getPaymentPlans() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.api_url + '/api/payment-plans');

                    this.paymentPlans = response.data.map(paymentPlan => {
                        const features = paymentPlan.features.map(feature => this.featureTranslations[feature]).filter(Boolean);

                        const maxUsers = paymentPlan.price.users === null ? Infinity : paymentPlan.price.users;

                        features.unshift((maxUsers !== Infinity ? maxUsers : 'oneindig') + ' gebruiker' + (maxUsers === 1 ? '' : 's'));
                        features.unshift(paymentPlan.price.credits_per_month ? (paymentPlan.price.credits_per_month + ' credits per maand') : 'Credits inkopen naar wens');

                        return {
                            ...paymentPlan,
                            features
                        };
                    });
                } catch (exception) {
                    console.error(exception);
                }

                this.loading = false;
            },

            async buyCredits() {
                this.loading = true;

                try {
                    const response = await this.$http.post(this.$root.api_url + '/api/credits', {
                        amount: this.buyCreditsData.amount
                    });

                    window.location = response.data.redirect_url;

                } catch (exception) {
                    console.error(exception);

                    this.loading = false;
                }
            },

            async selectPaymentPlan(paymentPlan) {
                this.loading = true;

                try {
                    const response = await this.$http.post(this.$root.api_url + '/api/payment-plans', {
                        id: paymentPlan.id
                    });

                    // For new mandates we have to redirect to Mollie.
                    if (response.data.redirect_url) {
                        window.location = response.data.redirect_url;
                        return;
                    }

                    this.paymentPlayChangeModal = false;
                    await this.$store.dispatch('updateCompany');
                } catch (exception) {
                    console.error(exception);
                }

                this.loading = false;
            },

            async cancelPlan(plan) {
                this.loading = true;

                try {
                    await this.$http.post(this.$root.api_url + '/api/payment-plans/' + plan.id + '/' + moment(plan.valid_from).format('YYYY-MM-DD') + '/cancel');

                    await this.$store.dispatch('updateCompany');
                } catch (exception) {
                    console.error(exception);
                }

                this.loading = false;
            },

            async togglePayModal(paymentPlan) {
                this.paymentPlayChangeModal = paymentPlan;

                if (paymentPlan.price.type === 'prepaid') {
                    this.requiresFirstPayment = false;
                } else {
                    this.requiresFirstPayment = await this.checkIfFirstPaymentIsRequired();
                }
            },

            async checkIfFirstPaymentIsRequired() {
                this.requiresFirstPaymentLoading = true;

                try {
                    const response = await this.$http.get(this.$root.api_url + '/api/company/recurring-payment-status');

                    this.requiresFirstPaymentLoading = false;

                    return !response.data.can_withdraw;
                } catch (exception) {
                    console.error(exception);
                }

                this.requiresFirstPaymentLoading = false;

                return true;
            },

            getNewPlanDate() {
                if (this.nextPlan) {
                    return moment(this.nextPlan.valid_from).add('1', 'month').format('DD MMMM YYYY');
                } else {
                    return moment().format('DD MMMM YYYY');
                }
            },

            isNextPlan(plan) {
                return (this.nextPlan && this.nextPlan.id === plan.id);
            },

            activePlan(plan) {
                return (this.company.current_payment_plan.id === plan.id);
            },

            getPaymentPlanFeatures(paymentPlan) {
                const idinEuros = paymentPlan?.price?.idin_euros_price;
                const smsEuros = paymentPlan?.price?.sms_euros_price;

                return [
                    idinEuros ? (formatCurrency(idinEuros) + ' per iDIN transactie') : null,
                    smsEuros ? (formatCurrency(smsEuros) + ' per SMS') : null,
                    ...paymentPlan.features,
                ].filter(Boolean);
            }

        }
    };
</script>
