var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid d-flex flex-column "},[_c('div',{staticClass:"row justify-content-center fill-width"},[_c('div',{staticClass:"col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5"},[_c('div',{staticClass:"card",staticStyle:{"max-width":"600px","margin":"0 auto"}},[_c('div',{staticClass:"card-body"},[_c('div',[_vm._m(0),_c('span',{staticClass:"clearfix"}),_c('ValidationObserver',{ref:"passwordChange",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[(!_vm.success)?_c('div',[_c('p',[_c('strong',[_vm._v("Vul hieronder je gegevens in om je wachtwoord te wijzigen.")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Huidig wachtwoord: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.oldpassword),expression:"request.oldpassword"}],staticClass:"form-control fusion-form-input",attrs:{"disabled":_vm.loading,"type":"password","id":"input-email","placeholder":""},domProps:{"value":(_vm.request.oldpassword)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.passwordChange.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "oldpassword", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Nieuw wachtwoord: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.password),expression:"request.password"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"password","placeholder":""},domProps:{"value":(_vm.request.password)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.passwordChange.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "password", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Nieuw wachtwoord herhalen: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.password_confirmation),expression:"request.password_confirmation"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"password","placeholder":""},domProps:{"value":(_vm.request.password_confirmation)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.passwordChange.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "password_confirmation", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"btn btn-primary btn-caps",attrs:{"type":"button","disabled":invalid || _vm.loading},on:{"click":_vm.passwordChange}},[_vm._v(" Wachtwoord wijzigen ")])]),_c('div',{staticClass:"text-center form-group mt-3"},[_c('small',[_c('router-link',{attrs:{"to":"/instellingen/account"}},[_vm._v("Terug")])],1)])]):_c('div',[_c('div',{staticClass:"alert alert-success"},[_vm._v("Je wachtwoord is gewijzigd, "),_c('router-link',{attrs:{"to":"/instellingen/account"}},[_vm._v("klik hier")]),_vm._v(" om terug te gaan naar de instellingen.")],1)]),(_vm.errormessage)?_c('div',{staticClass:"alert alert-danger mt-5"},[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()])]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 mt-5 text-center"},[_c('h3',{staticClass:"h3-green",staticStyle:{"font-size":"40px"}},[_vm._v("Wachtwoord wijzigen")])])}]

export { render, staticRenderFns }