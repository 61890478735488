<template>
	<section class="fusion-tb-header">


		<div class="d-sm-none row">
<!--			<div class="text-center top-mobile-menu" style="background-color:#0e6ace;height:48px;">-->
<!--				<a href="">Inloggen</a> <span style="color:#fff;">| </span>-->
<!--				<a href="">Registreren</a>-->
<!--			</div>-->
			<div>
				<img src="/images/logo_signly_45h.png" style="height:37px;margin: 15px 0px">
				<div class="p-3 float-end">
					<font-awesome-icon style="width:20px;height:20px;" icon="bars" @click="mobile_menu = !mobile_menu"></font-awesome-icon>
				</div>
			</div>

			<transition name="slide">
				<div class="mobile-menu" v-if="mobile_menu">
					<ul class="fusion-custom-menu fusion-menu-element-list" v-if="!user">
						<li class="menu-item" :class="checkCurrentRoute('/')">
							<span class="background-active transition-fade"></span>
							<a class="fusion-background-highlight" href="https://signly.nl">Home</a>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/login')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/login">Inloggen</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/register')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/register">Registreer</router-link>
						</li>
					</ul>

					<ul class="fusion-custom-menu fusion-menu-element-list" v-if="user && user.name">
						<li class="menu-item" :class="checkCurrentRoute('/')">
							<span class="background-active transition-fade "></span>
							<router-link class="fusion-background-highlight" to="/">Ondertekeningen</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/nieuwe-ondertekening')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/nieuwe-ondertekening">Nieuwe ondertekening</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/instellingen/credits')" v-if="company">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/instellingen/credits">Credits: {{ company.credits }}</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/instellingen/account')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/instellingen/account">{{ company ? company.name : 'Instellingen' }}</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/logout')">
							<span class="background-active transition-fade"></span>
							<a href="#" @click="logOut()" class="fusion-background-highlight">Uitloggen</a>
						</li>
					</ul>
				</div>
			</transition>
		</div>


		<div class="d-none d-sm-block">
			<div class="fusion-fullwidth fullwidth-box fusion-builder-row-1 fusion-flex-container p-lg-0 ">

				<router-link class="fusion-background-highlight" to="/">
					<img src="/images/logo_signly_45h.png" style="height:75px;padding: 15px 0px">
				</router-link>

				<nav class="fusion-menu-element-wrapper direction-row mode-dropdown expand-method-hover submenu-mode-dropdown mobile-mode-collapse-to-button mobile-size-full-absolute icons-position-left dropdown-carets-yes has-active-border-bottom-yes has-active-border-left-yes has-active-border-right-yes mobile-trigger-fullwidth-off mobile-indent-on mobile-justify-right main-justify-left mega-menu-loading expand-left submenu-transition-fade"
aria-label="Menu" data-breakpoint="1024" data-count="0" data-transition-type="fade" data-transition-time="300" aria-expanded="true">

					<button type="button" class="avada-menu-mobile-menu-trigger no-text" aria-expanded="false"><span class="inner"><span class="collapsed-nav-text"><span class="screen-reader-text">Toggle Navigation</span></span><span class="collapsed-nav-icon"><span class="collapsed-nav-icon-open businesscoachalign-right-solid"></span><span class="collapsed-nav-icon-close businesscoachtimes-solid"></span></span></span></button>

					<ul id="menu-business-coach-main-menu" class="fusion-menu fusion-custom-menu fusion-menu-element-list" v-if="!user">

						<li class="menu-item" :class="checkCurrentRoute('/')">
							<span class="background-active transition-fade"></span>
							<a class="fusion-background-highlight" href="https://signly.nl">Home</a>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/login')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/login">Inloggen</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/register')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/register">Registreer</router-link>
						</li>
					</ul>

					<ul class="fusion-menu fusion-custom-menu fusion-menu-element-list" v-if="user && user.name">
						<li class="menu-item" :class="checkCurrentRoute('/')">
							<span class="background-active transition-fade "></span>
							<router-link class="fusion-background-highlight" to="/">Ondertekeningen</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/nieuwe-ondertekening')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/nieuwe-ondertekening">Nieuwe ondertekening</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/instellingen/credits')" v-if="company">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/instellingen/credits">Credits: {{ company.credits }}</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/instellingen/account')">
							<span class="background-active transition-fade"></span>
							<router-link class="fusion-background-highlight" to="/instellingen/account">{{ company ? company.name : 'Instellingen' }}</router-link>
						</li>
						<li class="menu-item" :class="checkCurrentRoute('/logout')">
							<span class="background-active transition-fade"></span>
							<a href="#" @click="logOut()" class="fusion-background-highlight">Uitloggen</a>
						</li>
					</ul>

				</nav>
			</div>
		</div>
	</section>
</template>

<script>
	import {mapState} from "vuex";

	export default {

		computed: mapState({
			user: state => state.user,
			company: state => state.company,
		}),

		data: function () {
			return {
				mobile_menu: false,
			};
		},


		methods: {

			checkCurrentRoute(route) {
				if (this.$route.path === route) {
					return 'current-menu-item';
				}
			},


			logOut() {

				this.$store.dispatch('logout');
				this.$router.push('/login')
			}

		}
	}
</script>

<style scoped>
	.slide-enter-active {
		-moz-transition-duration: 0.2s;
		-webkit-transition-duration: 0.2s;
		-o-transition-duration: 0.2s;
		transition-duration: 0.2s;
		-moz-transition-timing-function: ease-in;
		-webkit-transition-timing-function: ease-in;
		-o-transition-timing-function: ease-in;
		transition-timing-function: ease-in;
	}

	.slide-leave-active {
		-moz-transition-duration: 0.2s;
		-webkit-transition-duration: 0.2s;
		-o-transition-duration: 0.2s;
		transition-duration: 0.2s;
		-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}

	.slide-enter-to, .slide-leave {
		max-height: 100px;
		overflow: hidden;
	}

	.slide-enter, .slide-leave-to {
		overflow: hidden;
		max-height: 0;
	}
</style>
