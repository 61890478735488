<template>
    <div class="container-fluid d-flex flex-column ">
        <div class="row justify-content-center fill-width">
            <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5">
                <div class="card" style="max-width: 600px; margin: 0 auto;">
                    <div class="card-body">

                        <div>
                            <div class="mb-5 mt-5 text-center">
                                <h3 class="h3-green" style="font-size:40px;">Wachtwoord vergeten</h3>
                                <p><strong>Vul hieronder je emailadres in om je wachtwoord te herstellen.</strong></p>
                            </div>

                            <span class="clearfix"></span>

                            <ValidationObserver ref="passwordReminder" v-slot="{ invalid }" tag="form">
                                <form>
                                    <div v-if="!success">
                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Emailadres: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="email" v-model="request.email" class="form-control fusion-form-input" id="input-email" placeholder="" @keydown.enter="passwordReminder">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="mt-1">
                                            <button class="btn btn-primary btn-caps" type="button" @click="passwordReminder" style="" :disabled="invalid || loading">
                                                Aanvraag versturen
                                            </button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="alert alert-success">Er is een wachtwoord herstel email verstuurd, klik op de link in de mail om je wachtwoord te herstellen.</div>
                                    </div>

                                    <div class="text-center form-group mt-3">
                                        <small>
                                            <router-link :to="`/login`">Inloggen</router-link>
                                        </small> |
                                        <small>
                                            <router-link :to="`/register`">Registreer</router-link>
                                        </small>
                                    </div>


                                    <div class="alert alert-danger mt-5" v-if="errormessage">{{ errormessage }}</div>
                                </form>

                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {

        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                request: {
                    email: null,
                },
                errormessage: null,
                success: false,
                loading: false
            };
        },

        mounted() {
            this.success = false;
        },

        methods: {
            async passwordReminder($event) {
                this.loading = true;

                if ($event) {
                    $event.preventDefault();
                }

                this.errormessage = null;

                try {
                    const email = this.request.email;

                    await this.$http.post(this.$root.api_url + '/api/auth/password-reminder', {
                        email,
                    });

                    this.success = true;
                } catch (exception) {
                    this.errormessage = 'Wachtwoord herstellen is helaas niet gelukt, controleer je gegevens en probeer het opnieuw';
                }

                this.loading = false;
            }
        }
    }

</script>
