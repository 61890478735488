<template>

    <div>
        <div class="home mt-5">

            <fullscreen-loading v-if="loading"></fullscreen-loading>

            <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div class="row">
                    <div class="col-12">
                        <div class="fusion-title">
                            <h3 class="h3-green" style="font-size:50px;">Emailtemplates</h3>
                        </div>
                        <p>
                            Bij emailtemplates is het mogelijk om standaard emailtemplates te definiëren die gekozen kunnen worden bij het aanmaken van een ondertekening. Binnen het onderteken proces worden meerdere emails verstuurd aan de hand van hun acties. Hieronder kan me de standaarden aanpassen en eventuele mergevelden gebruiken.
                        </p>
                    </div>
                </div>
            </div>


            <div class="row">

                <div class="col-md-3 mb-3">
                    <settings-menu></settings-menu>
                </div>

                <div class="col-md-9">
                    <h2 class="title-heading-left mb-3" style="">Emailtemplates beheren</h2>


                    <ValidationObserver ref="accountsettings" v-slot="{ invalid }" tag="form">

                        <div class="row mb-3">
                            <div class="col-6">
                                <label>Selecteer emailtemplate:</label>

                                <select class="form-control" v-model="emailtemplate" @click="getEmailTemplate">
                                    <option :value="null">Maak een keuze</option>
                                    <option :value="'sign_request_to_signer'">Ondertekening naar ondertekenaar</option>
                                    <option :value="'sign_request_to_signer_reminder'">Ondertekening naar ondertekenaar (herinnering)</option>
                                    <option :value="'signables_to_signer'">Getekende documenten naar ondertekenaar</option>
                                    <option :value="'signables_to_sign_request'">Getekende documenten naar initiator</option>
                                    <option :value="'email_verification'">Email verificatie</option>
                                    <option :value="'sign_request_cancelled_to_signer'">Annuleringsbevestiging naar ondertekenaar</option>
                                </select>

                            </div>
                        </div>

                        <div v-show="emailtemplate">

                            <div class="row mb-3">
                                <div class="col-6">
                                    <label>Onderwerp:</label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="request.subject" placeholder="">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>


                            <div class="row mb-3">
                                <div class="col-12" v-if="emailtemplate === emailtemplateEditor">
                                    <label>Template: </label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <trumbowyg v-model="request.message"
                                                   :config="editor_config"
                                                   class="form-control" name="content"
                                        />
                                        <small>Vragen over de emailtemplates/mergevelden? Bekijk onze <a href="https://signly.nl/veel-gestelde-vragen/" target="_blank" style="color:#0c29f3">veel gestelde vragen</a></small>
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>


                            <button class="btn btn-success btn-caps" type="button" @click="saveEmailTemplate()" :disabled="invalid">
                                <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                Opslaan
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FullscreenLoading from '@/components/FullscreenLoading';
    import SettingsMenu from '@/components/SettingsMenu';
    import Trumbowyg from 'vue-trumbowyg';
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import StateMixin from '../mixins/StateMixin';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {
        name: "EmailTemplates",
        components: {
            SettingsMenu,
            FullscreenLoading,
            ValidationProvider,
            Trumbowyg,
            ValidationObserver,
        },

        mixins: [StateMixin],

        data: function () {
            return {
                loading: false,
                emailtemplateEditor: null,
                emailtemplate_options: [
                    {
                        name: 'sign_request_to_signer'
                    },
                    {
                        name: 'sign_request_to_signer_reminder'
                    },
                    {
                        name: 'sign_request_cancelled_to_signer'
                    },
                    {
                        name: 'signables_to_signer'
                    },
                    {
                        name: 'signables_to_sign_request'
                    },
                    {
                        name: 'email_verification'
                    }
                ],
                request: {
                    id: null,
                    subject: null,
                    message: null,
                    name: null,
                },
                emailtemplate: null,
                emailtemplates: [],
                editor_config: {}
            }
        },

        watch: {
            emailtemplate() {
                const emailTemplate = this.mixin_emailtemplates.find(emailTemplate => emailTemplate.name === this.emailtemplate);

                this.editor_config.plugins.mergefields = {
                    mergefields: emailTemplate ? emailTemplate.merge_fields.map(mergeField => `[[${mergeField}]]`) : []
                };

                this.$nextTick(function(){
                    this.emailtemplateEditor = this.emailtemplate;
                })
            }
        },

        created() {
            this.editor_config = this.$root.default_editor_config;

            const hasMergeFieldButton = this.editor_config.btns.some(btn => {
                return btn[0] === 'mergefields';
            });

            // If already attached do not re-add the btn
            if (hasMergeFieldButton) {
                return;
            }

            this.editor_config.btns.push(['mergefields']);

            if (!this.editor_config.plugins) {
                this.editor_config.plugins = {};
            }
        },

        mounted() {
            this.getEmailTemplates();
        },

        methods: {

            async getEmailTemplates() {
                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/emailtemplates');

                    this.emailtemplates = response.data;

                    if (this.emailtemplate === null) {
                        this.emailtemplate = 'sign_request_to_signer';

                        const emailTemplate = this.mixin_emailtemplates.find(emailTemplate => emailTemplate.name === this.emailtemplate);

                        this.editor_config.plugins.mergefields = {
                            mergefields: emailTemplate ? emailTemplate.merge_fields.map(mergeField => `[[${mergeField}]]`) : []
                        };

                        this.$nextTick(function(){
                            this.emailtemplateEditor = this.emailtemplate;
                        })
                    }
                    this.getEmailTemplate();

                } catch (exception) {
                    console.log(exception)
                    this.errormessage = exception
                }
            },


            async saveEmailTemplate() {

                this.loading = true;

                try {

                    const response = await this.$http.post(this.$root.api_url + '/api/emailtemplate', this.request);

                    console.log(response);
                    await this.getEmailTemplates();

                } catch (exception) {
                    this.errormessage = exception
                }

                this.loading = false;
            },


            getEmailTemplate() {
                const current_user_emailtemplate = this.emailtemplates.find(emailtemplate => {
                    return emailtemplate.name === this.emailtemplate;
                })

                this.request = {
                    subject: null,
                    message: '',
                    name: this.emailtemplate
                }

                if (current_user_emailtemplate) {

                    this.request.id = current_user_emailtemplate.id;
                    this.request.subject = current_user_emailtemplate.subject;
                    this.request.message = current_user_emailtemplate.message;

                    // this does not update the button :(
                    const mergefields = this.emailtemplate_options.find(email_template_local => {
                        return email_template_local.name === current_user_emailtemplate.name;
                    })

                    this.editor_config.plugins.mergefields = {
                        mergefields: mergefields.merge_fields
                    };

                    this.$nextTick(function(){
                        this.emailtemplateEditor = this.emailtemplate;
                    })
                }else{
                    this.getTemplateFromMixin();
                }
            },

            /** No user template found. So fetch from the mixin */
            getTemplateFromMixin(){
                const mixin_template = this.mixin_emailtemplates.find(emailtemplate => {
                    return emailtemplate.name === this.emailtemplate;
                })

                this.request.subject = mixin_template.subject;
                this.request.message = mixin_template.template;
            }


        }
    }
</script>

<style scoped>

</style>