<template>
    <div class="home mt-5">

        <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column mb-5">

            <!--            <div class="row">-->
            <!--                <div class="fusion-title title fusion-title-6 fusion-sep-none fusion-title-text fusion-title-size-two">-->
            <!--                    <h2 class="float-start">Nieuwe ondertekening aanmaken</h2>-->
            <!--                    &lt;!&ndash;                    <button class="btn btn-primary float-end btn-caps">Nieuwe ondertekening</button>&ndash;&gt;-->

            <!--                </div>-->
            <!--            </div>-->
            <div class="fusion-title">
                <h3 class="h3-green" style="font-size:50px;">Nieuwe ondertekening</h3>
            </div>

            <div class="row mt-2 mb-4">
                <p>Start hieronder eenvoudig jouw ondertekenaanvraag. Het aanmaken van een ondertekening is erg eenvoudig en bestaat uit 3 kleine stappen; 1. Kies je ondertekenaar(s), 2. Upload je document(en) 3. Type een aangepast bericht voor de ontvanger(s).</p>
            </div>

            <fullscreen-loading v-if="loading"></fullscreen-loading>


            <div class="row">
                <div class="col-8">

                    <!--Start Step1-->
                    <div v-if="step === 1" class="mt-1">

                        <ValidationObserver ref="step1" v-slot="{ invalid }" tag="form">

                            <div v-for="(signer, index) in request.signers" :key="index" class="mb-5">

                                <h2 class="title-heading-left mb-3 float-start ">Ondertekenaar <span v-if="request.signers.length > 1">{{ index + 1 }}</span></h2>

                                <button v-if="index === 0 && request.signers.length < 10" class="btn btn-outline-secondary float-end" type="button" @click="addSigner()">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                    Ondertekenaar toevoegen
                                </button>
                                <button v-if="index > 0" v-tooltip="'Verwijder ondertekenaar'" @click="request.signers.splice(index, 1); $event.preventDefault();" class="btn btn-outline-danger btn-sm float-end">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </button>


                                <div class="row">
                                    <div class="col-6">
                                        <label>Voornaam:</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="signer.firstname" placeholder="" name="firstname">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>

                                    </div>

                                    <div class="col-6">
                                        <label>Achternaam:</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="signer.lastname" placeholder="" name="lastname">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>

                                    </div>
                                </div>

                                <div class="row mt-4">

                                    <div class="col-5">
                                        <label>Email:</label>
                                        <validation-provider rules="required|email" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="signer.email" placeholder="" name="email">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>

                                    <div class="col-7">
                                        <label>Verificatiemethodes:</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <multiselect
                                                v-model="signer.verifications"
                                                :options="verifications"
                                                label="text"
                                                track-by="type"
                                                :multiple="true"
                                                :selectLabel="'Klik om te selecteren'"
                                                :deselectLabel="'Klik om te verwijderen'"
                                                :selectedLabel="'Geselecteerd'"
                                                :placeholder="'Kies een verificatiemethode'"
                                                :searchable="false">
                                            </multiselect>
                                            <small class="error">{{ errors[0] }}</small>

                                        </validation-provider>

                                        <small class="d-block" v-if="hasIDINVerification(signer.verifications)">Het gebruik van een iDIN check kost {{ idinPrice }} extra per iDIN verificatie</small>
                                    </div>
                                </div>

                                <div class="row mt-4 mb-4" v-if="hasSMSVerificationMethod(signer) || hasIDINVerification(signer.verifications)">
                                    <div class="col-6" v-if="hasSMSVerificationMethod(signer)">
                                        <label>Mobielnummer:</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" name="phone" v-model="signer.phone">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                        <small class="d-block" v-if="smsPrice">Het versturen van een SMS kost {{ smsPrice }} extra per SMS</small>
                                    </div>

                                    <div class="col-6" v-if="hasIDINVerification(signer.verifications)">
                                        <label>iDIN gegevens:</label>
                                        <div class="d-flex flex-wrap">
                                            <div v-for="(idinServiceName, idinService) in idinServices"
                                                 :key="'signer_' + index + '_idin_service_' + idinService"
                                                 style="margin-right: 15px;"
                                            >
                                                <input type="checkbox"
                                                       class="custom-checkbox"
                                                       :id="'signer_' + index + '_idin_service_' + idinService"
                                                       v-model="signer.idinServices[idinService]"
                                                />
                                                <label style="top:0;cursor:pointer;" :for="'signer_' + index + '_idin_service_' + idinService">
                                                    {{ idinServiceName }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <input type="checkbox" class="custom-checkbox" :id="'signer_' + index + '_allow_form_edit'" v-model="signer.allow_form_edit">
                                        <label style="top:0;cursor:pointer;" :for="'signer_' + index + '_allow_form_edit'">
                                            Ondertekenaar mag PDF formulieren invullen
                                            <sup v-tooltip="'Wanneer het te ondertekenen bestand een formulier bevat kan de ondertekenaar dit formulier invullen.'">?</sup>
                                        </label>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-6">
                                        <label>Verstuur de eerste herinnering na dag :</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="number" class="fusion-form-input" v-model="signer.reminder_after" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>

                                    </div>

                                    <div class="col-6">
                                        <label>Maximaal aantal herinneringen:</label>
                                        <validation-provider rules="required|max_value:5|min_value:1" v-slot="{ errors }">
                                            <input type="number" class="fusion-form-input" v-model="signer.max_reminders" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                            </div>

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="float-end mt-3">
                                        <button class="btn btn-primary float-end btn-caps" @click="step = 2" :disabled="invalid">
                                            Volgende
                                            <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </ValidationObserver>


                    </div>
                    <!--end Step1-->


                    <!--start Step2-->
                    <div v-if="step === 2" class="row">
                        <h2 class="title-heading-left mb-3 float-start ">Document(en) </h2>

                        <div class="mt-4 float-start text-center" style="width: 400px;" v-for="(signable, index) in request.signables" :key="'signable_' + index">
                            <SignableEmbed
                                :signable="signable"
                                v-model="request.signables[index].config"
                                :signers="request.signers"
                                @delete="request.signables.splice(index, 1);"
                            />
                        </div>

                        <div class="row mt-5">
                            <div class="col-6">
                                <label class="upload">
                                    <span class="label_text">Klik hier om PDF/JPG/PNG/DOC/DOCX bestand(en) te selecteren</span>
                                    <input ref="file-input" multiple accept="application/pdf,image/png,image/jpeg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" @change="setFile($event)" class="" placeholder="">
                                </label>
                                <span v-if="errormessage" class="error">{{ errormessage }}</span>
                            </div>

                            <div class="col-6">
                                <TemplateSelector @select="addTemplateFile">
                                    <template v-slot:label>
                                        <label class="template-select">
                                            <span class="label_text">Klik om te selecteren uit templates</span>
                                        </label>
                                    </template>
                                </TemplateSelector>
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="float-start mt-3">
                                    <button class="btn btn-primary float-start btn-caps" @click="step = 1">
                                        <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                                        Vorige
                                    </button>
                                </div>
                                <div class="float-end mt-3">
                                    <button class="btn btn-primary float-end btn-caps" @click="step = 3" :disabled="request.signables.length === 0">
                                        Volgende
                                        <font-awesome-icon icon="chevron-right"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end Step2-->

                    <!--start Step3-->
                    <div v-if="step === 3">

                        <ValidationObserver ref="step3" v-slot="{ invalid }" tag="form">

                            <h2 class="title-heading-left mb-3 float-start ">Ondertekening </h2>

                            <div class="row mt-4">
                                <div class="col-6">
                                    <label>Ondertekening vervalt op:</label>

                                    <validation-provider rules="required|expire_min_date" v-slot="{ errors }">
                                        <VueCtkDateTimePicker :label="'Selecteer een datum & tijd'" :color="'#004188'" :button-color="'#004188'" format="YYYY-MM-DD HH:mm:ss" formatted="YYYY-MM-DD HH:mm" v-model="request.signrequest.expires_at"></VueCtkDateTimePicker>
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>

                                <div class="col-6" v-show="departments.data && departments.data.length !== 1">
                                    <label>Bedrijfsnaam:</label>
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input type="text" class="fusion-form-input" v-model="request.signrequest.department_id" placeholder="">
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>

                            <div class="row mt-4">

                                <div class="col-12">
                                    <input type="checkbox" class="custom-checkbox" id="email" v-model="request.signrequest.send_documents_to_initiator">
                                    <label style="top:0;cursor:pointer;" for="email">Getekende documenten ook versturen naar {{ $store.state.user.email }}</label>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-12">
                                    <input type="checkbox" class="custom-checkbox" id="sync" v-model="request.signrequest.is_sync">
                                    <label style="top:0;cursor:pointer;" for="sync">
                                        Ondertekenaars na elkaar laten tekenen
                                        <sup v-tooltip="'De ondertekenaars ondertekenen de documenten pas wanneer de voorgaande ondertekenaar het bestand heeft ondertekend.'">?</sup>
                                    </label>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12" v-if="emailtemplateEditor === emailtemplateEditing">
                                    <label>Bericht:</label>
                                    <validation-provider :rules="{
                                        required: true,
                                        anyOfMergefields: ['[[content]]', '[[link]]']
                                    }" v-slot="{ errors }">
                                        <trumbowyg v-model="request.signrequest.email_templates.sign_request_to_signer.template" :config="editor_config" class="form-control" name="content"></trumbowyg>
                                        <small class="error">{{ errors[0] }}</small>
                                    </validation-provider>

                                </div>
                            </div>

                            <div v-if="request.signrequest.custom_fields.length >= 1" class="mt-3">
                                <h2 class="title-heading-left mb-3 float-start ">Eigen velden</h2>

                                <div class="row mb-3" v-for="(custom_field, field_index) in request.signrequest.custom_fields" :key="'custom_field_' + field_index">
                                    <div class="col-5">
                                        <label>{{ custom_field.name }}<sup v-if="custom_field.description !== '' && custom_field.description !== null" v-tooltip="custom_field.description">?</sup>:</label>
                                        <validation-provider :rules="{required: custom_field.required, maxlength: 255}" v-slot="{ errors }" :key="'custom_input_' + field_index">
                                            <input type="text" class="fusion-form-input" v-model="custom_field.value" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12" v-if="markAsReadyError">
                                    <div class="alert alert-danger" v-html="markAsReadyError"/>
                                </div>

                                <div class="col-12">
                                    <div class="float-start mt-3">
                                        <button class="btn btn-primary float-end btn-caps" @click="step = 2">
                                            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                                            Vorige
                                        </button>
                                    </div>
                                    <div class="float-end mt-3">
                                        <button class="btn btn-success float-end btn-caps" type="button" @click="createSignRequest()" :disabled="invalid || incompleteSignRequest()">
                                            <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                            Verstuur
                                        </button>

                                        <div class="row">
                                            <div class="error" @click="step = 1" v-if="!hasSigner()" style="text-align: right; cursor:pointer;">U dient nog ondertekenaars toe te voegen.</div>
                                            <div class="error" @click="step = 2" v-if="request.signables.length === 0" style="text-align: right;cursor:pointer;">U dient nog documenten toe te voegen.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ValidationObserver>

                    </div>
                    <!--end Step3-->

                </div>

                <div class="col-4">
                    <div class="summary-box h-100 p-3">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="title-heading-left mb-3 float-start" style="">Ondertekenaars </h2>
                                    <button class="btn btn-outline-secondary btn-sm float-end" @click="step = 1">
                                        <font-awesome-icon class=" cursor-pointer" icon="pencil-alt"></font-awesome-icon>
                                    </button>
                                </div>
                                <div v-if="request.signers.length === 0 || !request.signers[0].email">Nog geen ondertekenaars toegevoegd</div>
                                <div v-for="(signer, index) in request.signers" :key="'signer_summ_' + index">
                                    <div v-if="signer.email">
                                        <div>{{ signer.firstname }} {{ signer.lastname }} - {{ signer.email }}</div>
                                        <div>Verificatiemethodes: {{ getVerificationsForSigner(signer) }}</div>
                                        <hr>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-4">
                                <div class="col-12">
                                    <h2 class="title-heading-left mb-3 float-start" style="">Documenten </h2>
                                    <button class="btn btn-outline-secondary btn-sm float-end" @click="step = 2" :disabled="false">
                                        <font-awesome-icon class=" cursor-pointer" icon="pencil-alt"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>
                            <div v-if="request.signables.length === 0">Nog geen documenten toegevoegd</div>
                            <div v-for="(signable, index) in request.signables" :key="'signable_summ_' + index">
                                <div>{{ signable.filename }}</div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12">
                                    <h2 class="title-heading-left mb-3 float-start" style="">Ondertekening </h2>
                                    <button class="btn btn-outline-secondary btn-sm float-end" @click="step = 3">
                                        <font-awesome-icon class=" cursor-pointer" icon="pencil-alt"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>
                            <div>Vervaldatum: {{ request.signrequest.expires_at }}</div>
                            <!--                            <div>Bericht: <span v-html="request.signrequest.email_templates.sign_request_to_signer"></span></div>-->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

    import {mapState} from 'vuex';
    import moment from 'moment';
    import Trumbowyg from 'vue-trumbowyg';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {email, max_value, min_value, required} from 'vee-validate/dist/rules';
    import StateMixin from '@/mixins/StateMixin';
    import * as Sentry from '@sentry/vue';
    import SignableEmbed from '../components/SignableEmbed.vue';
    import TemplateSelector from '../components/TemplateSelector.vue';
    import addVariablesToFormData from '../utils/addVariablesToFormData';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    extend('max_value', {
        ...max_value,
        message: 'Het aantal herinneringen mag niet hoger als 5 zijn'
    });

    extend('min_value', {
        ...min_value,
        message: 'Het aantal herinneringen moet minimaal 1 zijn'
    });

    extend('expire_min_date', {
        validate(value) {
            if (!value) {
                return false;
            }

            return moment(value).isAfter(moment());
        },
        message: 'De ondertekening moet minimaal vervallen na nu'
    });

    extend('email', {
        ...email,
        message: 'Dit veld is dient een emailadres te zijn'
    });

    extend('anyOfMergefields', {
        validate(value, mergeFields) {
            if (!value) {
                return mergeFields.length <= 0;
            }

            return mergeFields.some(mergeField => {
                return value.indexOf(mergeField) !== -1;
            });
        },
        message(field, options) {
            const mergeFields = [];

            for (const option in options) {
                if (!(/[0-9]+/.test(option))) {
                    continue;
                }

                mergeFields.push(options[option]);
            }

            return `Dit veld dient minimaal een van de mergevelden ${mergeFields.join(', ')} te bevatten`;
        }
    });
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    const defaultIdinServices = {
        name: true,
        address: true,
        gender: false,
        is_eighteen_or_older: false,
        date_of_birth: true,
        email: false,
        telephone: false,
        consumer_bin: true
    };

    export default {
        name: 'NewSigning',

        mixins: [StateMixin],

        data: function () {
            return {
                loading: false,
                errormessage: null,
                invalid: {},
                step3_invalid: {},
                step: 1,
                departments: {},
                markAsReadyError: null,
                config: {
                    // Get options from
                    // https://alex-d.github.io/Trumbowyg/documentation
                },
                tagoptions: [],
                emailtemplates: [],
                request: {
                    signers: [
                        {
                            firstname: '',
                            lastname: '',
                            email: '',
                            verifications: [
                                {
                                    type: 5,
                                    text: 'Handtekening'
                                },
                            ],
                            reminder_after: 3,
                            max_reminders: 5,
                            allow_form_edit: false,
                            idinServices: {
                                ...defaultIdinServices
                            }
                        },

                    ],
                    signrequest: {
                        send_documents_to_initiator: false,
                        expires_at: moment().add('1', 'week').format('YYYY-MM-DD HH:mm:ss'),
                        email_templates: {
                            sign_request_to_signer: {
                                template: '',
                                subject: '',
                            },
                        },
                        department_id: null,
                        emails: [],
                        custom_fields: [],
                    },
                    signables: [],
                },
                signrequest_id: null,
                emailtemplateEditing: 'sign_request_to_signer',
                emailtemplateEditor: null,
                editor_config: {},

                idinServices: {
                    name: 'Naam',
                    address: 'Adres',
                    gender: 'Geslacht',
                    is_eighteen_or_older: '18 jaar of ouder',
                    date_of_birth: 'Geboortedatum',
                    email: 'Emailadres',
                    telephone: 'Telefoonnummer',
                    consumer_bin: 'Gegevens bank',
                }

            };
        },

        computed: {
            ...mapState({
                user: state => state.user,
                company: state => state.company
            }),

            idinPrice() {
                const pricing = [];
                const idinCredits = this.company?.current_payment_plan?.price?.idin_credits_price || null;
                const idinEuros = this.company?.current_payment_plan?.price?.idin_euros_price || null;

                if (idinCredits) {
                    pricing.push(`${idinCredits} credits`);
                }

                if (idinEuros) {
                    pricing.push(`€ ${idinEuros}`);
                }

                return pricing.filter(Boolean).join(' en ');
            },

            smsPrice() {
                const pricing = [];
                const smsCredits = this.company?.current_payment_plan?.price?.sms_credits_price || null;
                const smsEuros = this.company?.current_payment_plan?.price?.sms_euros_price || null;

                if (smsCredits) {
                    pricing.push(`${smsCredits} credits`);
                }

                if (smsEuros) {
                    pricing.push(`€ ${smsEuros}`);
                }

                return pricing.filter(Boolean).join(' en ');
            }
        },

        components: {
            SignableEmbed,
            Trumbowyg,
            FullscreenLoading,
            ValidationProvider,
            ValidationObserver,
            TemplateSelector
        },

        async mounted() {
            this.loading = true;
            await this.getDepartments();
            await this.getEmailTemplates();
            await this.$store.dispatch('updateCompany');

            this.setStandardValues();

            this.setCustomFields();
            this.loading = false;
        },

        created() {
            this.editor_config = this.$root.default_editor_config;

            const hasMergeFieldButton = this.editor_config.btns.some(btn => {
                return btn[0] === 'mergefields';
            });

            // If already attached do not re-add the btn
            if (hasMergeFieldButton) {
                return;
            }

            this.editor_config.btns.push(['mergefields']);

            if (!this.editor_config.plugins) {
                this.editor_config.plugins = {};
            }
        },

        methods: {
            hasIDINVerification(verifications) {
                return verifications.some(verification => verification.type === 4/*iDIN*/);
            },

            async getDepartments() {
                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/departments');

                    this.departments = response.data;

                    if (response.data.data.length === 1) {
                        this.request.signrequest.department_id = response.data.data[0].id;
                    }

                } catch (exception) {
                    console.error(exception);
                }
            },

            async getEmailTemplates() {

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/emailtemplates');

                    this.emailtemplates = [...response.data];


                    for (let mix_template of this.mixin_emailtemplates) {


                        const user_template = this.emailtemplates.find((emailtemplate) => {
                            return emailtemplate.name === mix_template.name;
                        });


                        if (user_template && user_template.name) {
                            this.request.signrequest.email_templates[user_template.name] = {
                                template: user_template.message,
                                subject: user_template.subject
                            };
                        } else {
                            this.request.signrequest.email_templates[mix_template.name] = {
                                template: mix_template.template,
                                subject: mix_template.subject
                            };
                        }
                    }

                    const emailTemplate = this.mixin_emailtemplates.find(emailTemplate => emailTemplate.name === 'sign_request_to_signer');

                    this.editor_config.plugins.mergefields = {
                        mergefields: emailTemplate ? emailTemplate.merge_fields.map(mergeField => `[[${mergeField}]]`) : []
                    };

                    this.$nextTick(function () {
                        this.emailtemplateEditor = 'sign_request_to_signer';
                    });
                } catch (exception) {
                    console.error(exception);
                }
            },


            async createSignRequest() {
                this.loading = true;

                try {
                    await this.sendSignRequest();

                    for (const signer of this.request.signers) {
                        await this.addSigners(signer);
                    }

                    for (const signables of this.request.signables) {
                        await this.addSignables(signables);
                    }


                    await this.markAsReady();

                    this.$router.push({
                        name: 'ShowSigning',
                        params: {
                            id: this.signrequest_id
                        }
                    });
                } catch (exception) {
                    // Log the error for sentry...
                    console.error(JSON.stringify(this.request));
                    console.error(JSON.stringify(exception?.response?.data?.errors));
                    Sentry.captureMessage('Failed to create sign request, timestamp: ' + (new Date()).getTime());

                    const statusCode = exception?.response?.status;

                    if (statusCode === 402) {
                        this.markAsReadyError = 'U heeft niet voldoende credits om deze ondertekening te versturen. Klik <a href="/instellingen/credits">hier</a> om nieuwe credits aan te schaffen zodat u deze ondertekening alsnog kan versturen via het ondertekeningen overzicht.';
                    } else {
                        const errors = Object.values(exception?.response?.data?.errors || {});

                        if (errors.length) {
                            this.markAsReadyError = `<ul>
                                ${errors.map(error => `<li>${error[0]}</li>`).join('')}
                            </ul>`;
                        } else {
                            this.markAsReadyError = 'Er is iets mis gegaan.';
                        }
                    }
                }

                this.loading = false;
            },


            async sendSignRequest() {
                if (this.request.signrequest.send_documents_to_initiator) {
                    this.request.signrequest.emails.push(this.$store.state.user.email);
                }

                const response = await this.$http.post(this.$root.api_url + '/api/sign-requests', {
                    ...this.request.signrequest,
                    is_sync: this.request.signrequest.is_sync ? 'true' : 'false'
                });

                this.signrequest_id = response.data.id;
            },

            async addSignables(signable) {
                let formData = new FormData();

                formData.append('file', signable.file);
                formData.append('name', signable.filename);
                formData.append('filename', signable.filename);
                formData.append('type', 1);
                /** type 1 is file */
                formData.append('settings[forms_enabled]', true);

                addVariablesToFormData(formData, signable?.config?.variables || []);

                await this.$http.post(this.$root.api_url + `/api/sign-requests/${this.signrequest_id}/signables`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            },

            async addSigners(signer) {
                for (let verification of signer.verifications) {
                    if (verification.type === 1) /* Email */ {
                        verification.email = signer.email;
                    }

                    if (verification.type === 2) /* SMS */ {
                        verification.phone = signer.phone;
                    }

                    if (verification.type === 4) /* iDIN */ {
                        verification.services = Object.entries(signer.idinServices).filter(([, value]) => !!value).map(([key]) => key);
                    }
                }

                await this.$http.post(this.$root.api_url + `/api/sign-requests/${this.signrequest_id}/signers`, {
                    ...signer,
                    allow_form_edit: signer.allow_form_edit ? 'true' : 'false',
                });
            },


            incompleteSignRequest() {
                return this.request.signables.length === 0;
            },

            async markAsReady() {
                this.markAsReadyError = null;

                await this.$http.post(this.$root.api_url + `/api/sign-requests/${this.signrequest_id}/ready`);

                await this.$store.dispatch('updateCompany');
            },


            getVerificationsForSigner(signer) {
                return signer.verifications.map(verification => {
                    return verification.text;
                }).join(', ');
            },

            async addSigner() {

                this.request.signers.push({
                    firstname: '',
                    lastname: '',
                    email: '',
                    verifications: [
                        {
                            type: 5,
                            text: 'Handtekening'
                        },
                    ],
                    reminder_after: 3,
                    max_reminders: 5,
                    allow_form_edit: false,
                    idinServices: {
                        ...defaultIdinServices
                    }
                });

            },

            hasSMSVerificationMethod(signer) {
                return signer.verifications.some(verification => {
                    return verification.type === 2;
                });
            },


            hasSigner() {
                return this.request.signers.some(signer => {
                    return signer.email !== '' && signer.firstname !== '' && signer.lastname !== '';
                });
            },

            async setFile(event) {

                this.loading = true;
                this.errormessage = null;

                for (const file of event.target.files) {
                    const filename = file.name.substring(0, file.name.lastIndexOf('.'));
                    const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

                    const allowedExtensions = ['pdf', 'png', 'jpg', 'doc', 'docx'];
                    if (!allowedExtensions.includes(extension.toLowerCase())) {
                        this.loading = false;
                        this.errormessage = 'Het bestandstype van 1 of meerdere bestanden is niet toegestaan.';
                        continue;
                    }

                    let formData = new FormData();

                    formData.append('file', file);

                    try {
                        const response = await this.$http.post(this.$root.api_url + `/api/upload-file`, formData, {
                            responseType: 'blob',
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            transformResponse: [function (data) {
                                return new window.Blob([data], { type: 'application/pdf' })
                            }]
                        });


                        const newfile = new File([response.data], 'test.pdf', {type: 'application/pdf'});

                        this.request.signables.push({
                            file: newfile,
                            filename,
                            last_modified: newfile.lastModified,
                            size: newfile.size,
                            src: URL.createObjectURL(newfile),
                            showModal: true,
                            config: {
                                variables: []
                            }
                        });

                    } catch (exception) {
                        console.log(exception);
                        this.errormessage = exception;
                    }
                    this.loading = false;

                    // Set the target value to something empty to allow the same file to be uploaded again, otherwise the DOM will not trigger the change event because it thinks the same file is selected
                    event.target.value = '';
                }
            },

            async addTemplateFile({file}) {
                this.request.signables.push(file);
            },

            setStandardValues() {
                this.request.signrequest.send_documents_to_initiator = this.company.send_signed_documents_to_email;
            },

            setCustomFields() {
                this.request.signrequest.custom_fields = this.company?.custom_fields?.map(custom_field => {
                    return {
                        ...custom_field,
                        value: ''
                    }
                }) || [];
            },
        }
    };
</script>

<style type="text/css">

    .summary-box {
        border-radius: 10px;
        padding: 10px;
        background-color: #f6f8fb
    }

    .template-select,
    .upload {
        width: 350px;
        height: 140px;
        background-image: url("/images/upload-file-90.png");
        display: flex;
        background-repeat: no-repeat;
        background-size: 90px 90px;
        background-position: center top;
        /*border: 2px dotted black;*/
        align-items: center;
        justify-content: center;
        padding: 31px;
        cursor: pointer;
        position: relative;
    }

    .template-select {
        background-image: url("/images/icon.png");
    }

    .template-select .label_text,
    .upload .label_text {
        display: block;
        position: absolute;
        bottom: 0;
        text-align: center;
    }

    .upload input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }


    @media only screen and (max-width: 1024px) {
        .fusion-title.fusion-title-6 {
            margin-top: 10px !important;
            margin-right: 0px !important;
            margin-bottom: 15px !important;
            margin-left: 0px !important;
        }
    }

    @media only screen and (max-width: 640px) {
        .fusion-title.fusion-title-6 {
            margin-top: 10px !important;
            margin-right: 0px !important;
            margin-bottom: 10px !important;
            margin-left: 0px !important;
        }
    }

    .signable-template {
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        text-align: left;
        margin-bottom: 5px;
        cursor: grab;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
</style>