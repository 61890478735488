<template>
    <div>
        <div class="home mt-5">

            <fullscreen-loading v-if="loading"></fullscreen-loading>

            <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div class="row">
                    <div class="col-12">
                        <div class="fusion-title">
                            <h3 class="h3-green" style="font-size:50px;">Instellingen</h3>
                        </div>
                        <p>
                            Hieronder is het mogelijk verschillende instellingen te maken waaronder algemene instellingen, afdeling instellingen en huisstijl instellingen.
                        </p>

                    </div>
                </div>
            </div>


            <div class="row mb-5">

                <div class="col-md-3 mb-3">
                    <settings-menu></settings-menu>
                </div>


                <div class="col-md-9">
                    <h2 class="title-heading-left " style="">Account instellingen <sup v-tooltip="'Hier is het mogelijk om algemene instellingen te wijzigen. De bedrijfs en adres gegevens worden bijvoorbeeld gebruikt ter facturatie en zijn zichtbaar bij een ondertekening.'">?</sup></h2>


                    <ValidationObserver ref="accountsettings" v-slot="{ invalid }" tag="form" class="mt-3">

                        <div class="row mb-3">
                            <div class="col-6">
                                <label>Bedrijfsnaam: <sup>*</sup></label>
                                <validation-provider rules="required" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.name" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="col-6">
                                <label>T.n.v.:</label>
                                <validation-provider rules="maxlength:100" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.tnv" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                        </div>

                        <div class="row mb-3">
                            <div class="col-6">
                                <label>Straat:</label>
                                <validation-provider rules="maxlength:100" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.street" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="col-3">
                                <label>Huisnummer:</label>
                                <validation-provider rules="maxlength:11" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.housenumber" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="col-3">
                                <label>Toevoeging:</label>
                                <validation-provider rules="maxlength:20" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.housenumber_addition" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-4">
                                <label>Postcode:</label>
                                <validation-provider rules="maxlength:11" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.postal" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="col-8">
                                <label>Plaats:</label>
                                <validation-provider rules="maxlength:40" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.address.city" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="col-lg-6 col-12">
                                <label>Telefoonnummer:</label>
                                <validation-provider rules="maxlength:30" v-slot="{ errors }">
                                  <input type="tel" class="fusion-form-input input-text" v-model="company.phone" placeholder="">
                                  <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div v-if="hasFeature(company, 'departments')">
                            <div class="row mb-3 mt-5">
                                <div class="col-6">
                                    <h2 class="title-heading-left mb-3 " style="">Email instellingen <sup v-tooltip="'Hier kan je de naam aangeven waarvan hij moet emailen. In nabije toekomst is het ook mogelijk om jouw email adres te koppelen'">?</sup></h2>
                                </div>
                            </div>

                            <div v-for="department in departments.data" :key="department.id">
                                <div class="row mb-3 d-none">
                                    <div class="col-12">
                                        <label>Afdeling: <sup>*</sup></label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="department.name" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <label>Emailadres:</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="mail_from" :disabled="true" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>

                                    <div class="col-6">
                                        <label>Email van: <sup>*</sup></label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="department.mail_name" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="title-heading-left mt-5">Standaard ondertekening instellingen<sup v-tooltip="'Wijzig standaard instellingen voor een nieuwe ondertekening.'">?</sup></h2>

                        <div class="row mb-3 mt-3">
                            <div class="col-12">
                                <input type="checkbox" class="custom-checkbox" id="email" v-model="company.send_signed_documents_to_email">
                                <label style="top:0;cursor:pointer;" for="email">Getekende documenten ook versturen naar {{ user.email }}.</label>
                            </div>
                        </div>

                        <h2 class="title-heading-left mt-5" style="">Wachtwoord wijzigen <sup v-tooltip="'Wijzig regelmatig je wachtwoord en kies een sterk wachtwoord met letters, cijfers en tekens.'">?</sup></h2>


                        <div class="row mb-3 mt-3">
                            <div class="col-6">

                                <validation-provider rules="required" v-slot="{ errors }">
                                    <router-link :to="{name: 'PasswordChange'}" class="btn btn-primary w-50 mb-3">
                                        <font-awesome-icon class="menu-icon" icon="user-circle"></font-awesome-icon>
                                        Wachtwoord wijzigen
                                    </router-link>
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <h2 class="title-heading-left mt-5" style="">Huisstijl instellingen <sup v-tooltip="'Upload hier je logo. Dit logo zie je terugkomen boven in de email met het ondertekenverzoek. Maar ook boven in de pagina waar de ondertekening plaatsvindt'">?</sup></h2>


                        <div class="row mb-3 mt-3">
                            <div class="col-6">
                                <label>Huidig logo:</label>
                                <div class="mb-3">
                                    <img id="uploaded_file" v-if="uploaded_logo" :src="uploaded_logo" alt="your image" style="height: 70px;"/>
                                    <div v-if="company.logo_url && !uploaded_logo">
                                        <img :src="company.logo_url" style="height: 70px;">
                                        <div><input v-model="company.delete_logo" type="checkbox" id="deletelogo" class="formc-control"> <label for="deletelogo" style="padding-top: 5px">Vink aan om het logo verwijderen</label></div>
                                    </div>
                                    <div v-else class="mt-2"><small>Geen logo ingesteld. Klik op de button hieronder om een logo te uploaden</small></div>
                                </div>

                                <validation-provider rules="required" v-slot="{ errors }">
                                    <label class="upload_btn">
                                        <label for="file">Upload logo</label>
                                        <input ref="file-input" accept="image/*" id="file" type="file" @change="setFile($event)" class="" placeholder="">
                                    </label>
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <h2 class="title-heading-left mt-5" style="">Verificatie instellingen</h2>


                        <div class="row mb-3 mt-3">
                            <div class="col-12">
                                <label>SMS Naam: <sup>*</sup></label>
                                <validation-provider rules="required|maxlength:11" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.sms_name" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row" v-if="hasFeature(company, 'webhooks')">
                            <div class="col-12 mb-3 mt-5">
                                <h2 class="title-heading-left" style="">Webhook instellingen <sup v-tooltip="getWebHookTooltip()">?</sup></h2>
                            </div>

                            <div class="col-12">
                                <label>Webhook URL:</label>
                                <validation-provider rules="maxlength:255" v-slot="{ errors }">
                                    <input type="text" class="fusion-form-input" v-model="company.webhook_url" placeholder="">
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <div class="row"><!-- v-if="hasFeature(company, 'custom_fields')"-->
                            <div class="col-12 mb-3 mt-5">
                                <h2 class="title-heading-left" style="">Eigen velden <sup v-tooltip="getCustomFieldsTooltip()">?</sup></h2>
                            </div>

                            <div class="col-12">
                                <button class="btn btn-outline-secondary" type="button" @click="addCustomField()">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                    Eigen veld toevoegen
                                </button>
                            </div>
                            <div v-if="company.custom_fields.length > 0" class="col-5">Naam:</div>
                            <div v-if="company.custom_fields.length > 0" class="col-5">Omschrijving:</div>
                            <div v-if="company.custom_fields.length > 0" class="col-2">Verplicht:</div>
                            <div class="col-12" v-for="(customField, index) in company.custom_fields" :key="index + '_custom'">
                                <div class="row mb-1" v-if="!customField.deleted">
                                    <div class="col-5">
                                        <validation-provider rules="required|maxlength:128" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="customField.name" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                    <div class="col-5">
                                        <validation-provider rules="maxlength:255" v-slot="{ errors }">
                                            <input type="text" class="fusion-form-input" v-model="customField.description" placeholder="">
                                            <small class="error">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                    <div class="col-1">
                                        <input type="checkbox" class="custom-checkbox" :id="'required_' + index" v-model="customField.required" style="margin-top: 12px;">
                                        <label :for="'required_' + index"></label>
                                    </div>
                                    <div class="col-1">
                                        <button v-tooltip="'Verwijder custom veld'" @click.prevent="toggleDeleteCustomField(index)" class="btn btn-outline-danger btn-sm float-end" style="margin-top: 9px;">
                                            <font-awesome-icon icon="trash"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                                <div v-if="customField.deleted" class="row">
                                    <div class="col-5">
                                        <div class="text-decoration-line-through">{{ customField.name }}</div>
                                    </div>
                                    <div class="col-5">
                                        <div class="text-decoration-line-through">{{ customField.description }}</div>
                                    </div>
                                    <div class="col-1">
                                        <span><font-awesome-icon icon="checkmark"></font-awesome-icon></span>
                                    </div>
                                    <div class="col-1">
                                        <button v-tooltip="'Ongedaan maken verwijderen'" @click.prevent="toggleDeleteCustomField(index)" class="btn btn-outline-warning btn-sm float-end">
                                            <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <button class="btn btn-success float-end btn-caps mt-3 " type="button" @click="saveData()" :disabled="invalid">
                            <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                            Opslaan
                        </button>

                    </ValidationObserver>

                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger" v-if="errormessage">
                                {{ errormessage }}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import FullscreenLoading from "@/components/FullscreenLoading";
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import SettingsMenu from "@/components/SettingsMenu";
    import {mapState} from "vuex";

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });
    //
    // extend('max', {
    //   ...max,
    //   // message: 'Dit veld is verplicht'
    // });

    extend('maxlength', {
        validate(value, args) {
            return Number(value.toString().length) <= Number(args.length);
        },
        params: ['length'],
        message: 'De waarde in dit veld is te lang'
    });

    export default {
        name: "AccountSettings",
        components: {
            SettingsMenu,
            FullscreenLoading,
            ValidationProvider,
            ValidationObserver,
        },

        data: function () {
            return {
                loading: false,
                errormessage: null,
                mail_from: 'no-reply@signly.nl',
                uploaded_logo: null,
                departments: {
                    data: [
                        {
                            name: null,
                            mail_from: 'no-reply@signly.nl',
                            mail_name: null,
                        }
                    ]
                },
                company: {
                    address: {
                        tnv: null,
                        street: null,
                        housenumber: null,
                        housenumber_addition: null,
                        postal: null,
                        city: null,
                    },
                    phone: null,
                    logo: null,
                    delete_logo: false,
                    webhook_url: null,
                    send_signed_documents_to_email: false,
                    custom_fields: []
                },
                settings: {
                    account: null,
                    email: 'no-reply@signly.nl',
                    department_name: 'no-reply@signly.nl'
                }
            }
        },

        async mounted() {
            this.loading = true;
            await this.getDepartments();
            await this.getCompany();
            this.loading = false;

        },

        computed: {
            ...mapState({
                user: state => state.user,
            }),
        },

        methods: {

            async getDepartments() {
                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/departments');

                    this.departments = response.data;

                } catch (exception) {
                    this.errormessage = exception
                }
            },

            async getCompany() {
                try {
                    const response = await this.$http.get(this.$root.api_url + '/api/company');

                    this.company = response.data;
                    if (!response.data.address) {
                        this.company.address = {
                            tnv: null,
                            street: null,
                            housenumber: null,
                            housenumber_addition: null,
                            postal: null,
                            city: null,
                        }
                    }

                    this.company.custom_fields = response.data.custom_fields.map(custom_field => {
                        return {
                            ...custom_field,
                            deleted: false
                        }
                    })

                } catch (exception) {
                    this.errormessage = exception
                }
            },

            async saveData() {

                this.loading = true;

                await this.saveCompany();

                if (this.hasFeature(this.company, 'departments')) {
                    for (let department of this.departments.data) {
                        await this.saveDepartment(department);
                    }
                }

                await this.getCompany();

                this.loading = false;
            },

            async saveCompany() {

                this.errormessage = null;

                const formData = new FormData();


                if (this.company.delete_logo) {
                    this.company.logo = '';
                }

                if (this.company.logo !== undefined) {
                    formData.append('logo', this.company.logo);
                }

                formData.append('name', this.company.name);
                formData.append('phone', this.company.phone);
                formData.append('sms_name', this.company.sms_name);
                formData.append('send_signed_documents_to_email', (this.company.send_signed_documents_to_email ? 1 : 0));

                if (this.company.webhook_url) {
                    formData.append('webhook_url', this.company.webhook_url);
                }

                this.checkAddresFormData(formData);
                this.checkCustomFieldsFormData(formData);

                try {
                    const response = await this.$http.post(this.$root.api_url + '/api/company', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    this.uploaded_logo = null;

                    this.$notify({
                        group: 'default',
                        title: 'Gegevens opgeslagen',
                        text: 'Je wijzigingen zijn opgeslagen',
                        type: 'success',
                        // duration: 2000,
                    });

                    this.$store.dispatch('company', response.data);
                } catch (exception) {
                    this.errormessage = exception.response.data;
                }
            },

            checkAddresFormData(formData) {

                if (this.company.address.tnv) {
                    formData.append('address[tnv]', this.company.address.tnv)
                }
                if (this.company.address.housenumber) {
                    formData.append('address[housenumber]', this.company.address.housenumber)
                }
                if (this.company.address.street) {
                    formData.append('address[street]', this.company.address.street)
                }
                if (this.company.address.housenumber_addition) {
                    formData.append('address[housenumber_addition]', this.company.address.housenumber_addition)
                }
                if (this.company.address.postal) {
                    formData.append('address[postal]', this.company.address.postal)
                }
                if (this.company.address.city) {
                    formData.append('address[city]', this.company.address.city)
                }

                return formData
            },

            checkCustomFieldsFormData(formData) {
                if (this.company.custom_fields.length <= 0) {
                    return formData;
                }

                this.company.custom_fields.filter(customField => !customField.deleted).forEach((customField, index) => {
                    formData.append(`custom_fields[${index}][name]`, customField.name);
                    formData.append(`custom_fields[${index}][description]`, customField.description || '');
                    formData.append(`custom_fields[${index}][required]`, customField.required);
                });

                return formData;
            },

            async saveDepartment(department) {

                const post_data = {
                    name: department.name,
                    mail_name: department.mail_name
                }

                try {
                    const response = await this.$http.post(`/api/departments/${department.id}`, post_data);

                    console.log(response);

                } catch (exception) {
                    this.errormessage = exception
                }
            },

            getWebHookTooltip() {
                return 'Hier is het mogelijk om hier een webhook in te stellen. Signly zal deze webhook triggeren bij: <br>' +
                    '<br> - Bij status wijzigen van ondertekenaaanvraag' +
                    '<br> - Het versturen van een emailverificatie' +
                    '<br> - Bij email verificatie' +
                    '<br> - Bij handtekening verificatie';
            },

            getCustomFieldsTooltip() {
                return 'Hier kan een veld worden toegevoegd aan een ondertekening. Dit veld wordt bij een ondertekening gevuld door de persoon die de ondertekening aanmaakt. De waarde wordt opgeslagen op de ondertekening.';
            },

            setFile(event) {

                this.uploaded_logo = URL.createObjectURL(event.target.files[0])

                console.log(event.target.files[0])
                this.company.logo = event.target.files[0];
            },

            addCustomField() {
                this.company.custom_fields.push({
                    name: '',
                    description: '',
                    required: false,
                    deleted: false
                });
            },

            toggleDeleteCustomField(index) {
                this.company.custom_fields[index].deleted = !this.company.custom_fields[index].deleted;
            }
        }
    }
</script>

<style scoped>

    .upload_btn {
        display: block;
        position: relative;
        width: 200px;
        height: 50px;
        border-radius: 25px;
        background: linear-gradient(40deg, #00ca72, #10ab67);
        /*box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);*/
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        transition: transform .2s ease-out;
    }

    .upload_btn input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }


</style>