<template>
    <div class="container-fluid d-flex flex-column ">
        <div class="row justify-content-center fill-width">
            <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5">
                <div class="card" style="max-width: 600px; margin: 0 auto;">
                    <div class="card-body">

                        <div>
                            <div class="mb-5 mt-5 text-center">
                                <h3 class="h3-green" style="font-size:40px;">Wachtwoord wijzigen</h3>

                            </div>

                            <span class="clearfix"></span>

                            <ValidationObserver ref="passwordChange" v-slot="{ invalid }" tag="form">
                                <form>
                                    <div v-if="!success">
                                        <p><strong>Vul hieronder je gegevens in om je wachtwoord te wijzigen.</strong></p>
                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Huidig wachtwoord: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="password" v-model="request.oldpassword" class="form-control fusion-form-input" id="input-email" placeholder="" @keydown.enter="passwordChange">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Nieuw wachtwoord: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="password" v-model="request.password" class="form-control" placeholder="" @keydown.enter="passwordChange">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Nieuw wachtwoord herhalen: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="password" v-model="request.password_confirmation" class="form-control" placeholder="" @keydown.enter="passwordChange">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="mt-1">
                                            <button class="btn btn-primary btn-caps" type="button" @click="passwordChange" style="" :disabled="invalid || loading">
                                                Wachtwoord wijzigen
                                            </button>
                                        </div>
                                        <div class="text-center form-group mt-3">
                                            <small>
                                                <router-link to="/instellingen/account">Terug</router-link>
                                            </small>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="alert alert-success">Je wachtwoord is gewijzigd, <router-link to="/instellingen/account">klik hier</router-link> om terug te gaan naar de instellingen.</div>
                                    </div>



                                    <div class="alert alert-danger mt-5" v-if="errormessage">{{ errormessage }}</div>
                                </form>

                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {

        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                request: {
                    oldpassword: null,
                    password: null,
                    passwordconfirmation: null,
                },
                errormessage: null,
                success: false,
                loading: false,
            };
        },

        mounted() {
            this.success = false;
        },

        methods: {
            async passwordChange($event) {
                if ($event) {
                    $event.preventDefault();
                }

                if (!this.passwordConfirmed()){
                    this.errormessage = 'De wachtwoorden komen niet overeen'
                    return;
                }

                this.loading = true;

                this.errormessage = null;

                try {
                    const oldpassword = this.request.oldpassword;
                    const password = this.request.password;
                    const password_confirmation = this.request.password_confirmation;

                    await this.$http.post(this.$root.api_url + '/api/user/password-change', {
                        oldpassword,
                        password,
                        password_confirmation
                    });

                    this.success = true;
                } catch (exception) {

                    this.errormessage = 'Wachtwoord wijzigen is helaas niet gelukt, controleer je gegevens en probeer het opnieuw';
                }

                this.loading = false;
            },

            passwordConfirmed(){
                return (this.request.password === this.request.password_confirmation);
            }
        }
    }

</script>
