<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">API sleutels</h3>
                            </div>
                            <p>
                                Hieronder vind je al jouw API-sleutels terug. Wil je een nieuwe API-sleutel aanmaken? Neem dan <a class="underline" href="mailto:info@signly.nl">contact</a> met ons op voor de mogelijkheden.
                            </p>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-3 mb-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-md-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">API sleutels </h2>

                            <div v-if="!accessTokens.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Er zijn nog geen API sleutels ingesteld. Neem <a class="underline" href="mailto:info@signly.nl">contact</a> met ons op voor het aanmaken van een sleutel.
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="accessToken in accessTokens.data" :key="accessToken.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">{{ accessToken.name }}</h2>
                                                    <div><small>Datum: {{ accessToken.created_at | moment('DD-MM-YYYY HH:mm') }}</small></div>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                {{ accessToken.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SettingsMenu from "@/components/SettingsMenu";
    import FullscreenLoading from "@/components/FullscreenLoading";

    export default {

        components: {
            FullscreenLoading,
            SettingsMenu
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                accessTokens: {
                    data: []
                }
            }
        },

        mounted() {
            this.getAccessTokens();
        },

        methods: {
            async getAccessTokens(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/access-tokens', {
                        params: {
                            page: page || this.accessTokens.current_page,
                            order: this.order,
                            limit: 10
                        }
                    });

                    this.accessTokens = response.data;

                } catch (exception) {
                    this.errormessage = exception
                }

                this.loading = false;
            },
        }
    }

</script>

<style scoped>

</style>