<template>
    <div id="app" class="fusion-wrapper" :class="getBackgroundForRoute()" style="background-color:#fff;" >
        <div class="container">

            <navigation></navigation>
            <notifications group="default" />

            <router-view/>

        </div>
    </div>
</template>

<script>



    import Navigation from "@/components/Navigation";
    import {mapState} from "vuex";


    export default {


        computed: mapState({
            user: state => state.user,
            ceremony: state => state.ceremony,
        }),

        components: {
            Navigation
        },

        mounted() {

            this.$store.dispatch('setLocale', 'nl');
        },

        methods: {
            getBackgroundForRoute(){
                const routesWithBackground = [
                    'Login',
                    'Register',
                    'PasswordReminder',
                    'PasswordReset'
                ];

                if (routesWithBackground.indexOf(this.$route.name) !== -1){
                    return 'with-background';
                }

            }
        }

    }
</script>

<style>



</style>

