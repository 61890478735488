export default function (formData, variables) {
    for (const variableIndex in variables) {
        const variable = variables[variableIndex];

        if (variable.value === null) {
            continue;
        }

        formData.append('variables[' + variableIndex + '][x]', variable.x);
        formData.append('variables[' + variableIndex + '][y]', variable.y);
        formData.append('variables[' + variableIndex + '][width]', variable.width);
        formData.append('variables[' + variableIndex + '][height]', variable.height);
        formData.append('variables[' + variableIndex + '][value]', variable.value);
        formData.append('variables[' + variableIndex + '][type]', variable.type);

        if (variable.options) {
            for (const [key, value] of Object.entries(variable.options)) {
                formData.append('variables[' + variableIndex + '][options][' + key + ']', value);
            }
        }
        formData.append('variables[' + variableIndex + '][page]', variable.page);
    }
}