import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

window.loadSentry = () => {
    console.log('Load sentry');

    if (typeof window.wobbleminor !== 'undefined' && typeof window.wobbleminor.e34rnl !== 'undefined' && !window.wobbleminor.e34rnl.i && window.wobbleminor.e34rnl.k) {
        const structure = window.wobbleminor.e34rnl;

        Sentry.init({
            Vue: Vue,
            dsn: structure.k,
            debug: structure.d,
            integrations: [new Integrations.BrowserTracing({
                tracingOrigins: [
                    window.location.host,
                    /^\//
                ]
            })],
            tracingOptions: {
                trackComponents: true,
            },
            environment: process.env.NODE_ENV,
            release: structure.sv || 'dev'
        });

        Sentry.setContext('versions', {
            frontend: structure.sv,
            backend: structure.sv
        });

        window.wobbleminor.e34rnl.i = true;
    }
};
