import Axios from "axios";

const dataLoadMiddleware = async (store, to, from, next, types) => {

    const requestConfig = store.state.token ? {
        headers: {
            Authorization: `Bearer ${store.state.token}`
        }
    } : {};


    if (types.indexOf('company') !== -1 && store.state.company === null && store.state.token) {
        const response = await Axios.get('/api/company', requestConfig);

        store.dispatch('company', response.data);
    }

    return next();
}

export default dataLoadMiddleware;
